import {
	IRequestConfig,
	HTTPClient as HTTPClientCore,
} from "./HTTPClient";
import qs from "qs";
import axios from "axios";
// import * as qs from "qs";
import { Storage } from "../Storage";

class HTTPClient extends HTTPClientCore {
	/**
	 * Overridden method adds CancelToken symbol, that allow redux-saga'
	 * "takeLatest" function to cancel any requests automatically.
	 */
	public makeRequest(config: IRequestConfig) {
		const source = axios.CancelToken.source();

		const request: any = super.makeRequest({
			...config,
			params: {
				...config.params,
				_: Date.now(),
				sid: Storage.get("sid"),
			},
			cancelToken: source.token,
		});

		return request;
	}
}

const APIClient = new HTTPClient({
	baseURL: process.env.REACT_APP_API_URL || "",
	withCredentials: true,
	transformRequest: [(data) => qs.stringify(data)],
});

const JSONClient = new HTTPClient({
	baseURL: process.env.REACT_APP_JSON_SPORT_URL || "",
});

const FinalsMatchesClient = new HTTPClient({
	baseURL: process.env.REACT_APP_FINALS_URL || "",
});

export const Api = {
	JSON: {
		rounds: () => JSONClient.get(`rounds.json`),
		final_rounds: () => JSONClient.get(`fin.json`),
		ladder: () => JSONClient.get(`ladder.json`),
		squads: () => JSONClient.get(`squads.json`),
		finals: () => FinalsMatchesClient.get(`fin.json`),
	},
	Auth: {
		login: (params: object) =>
			APIClient.post("nrlaccount/auth_code_ladder_predictor", params),
	},
	User: {
		create: (params: object) => APIClient.post("user/create", params),
	},
	Predictions: {
		save: (params: object) =>
			APIClient.post("predictor/ladder_prediction", params),
	},
	Polling: {
		save: (p: object) => APIClient.post("polling/save", p),
	},
};

export * from "./ApiErrors";

export default Api;
