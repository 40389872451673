/**
 * Squad model
 */
export class Squad {
	public id: number = 0;
	public full_name: string = "";
	public name: string = "";
	public short_name: string = "";

	constructor(data: Squad) {
		Object.assign(this, data);
	}
}
