/**
 * Set fixed state to body (overflow: hidden;)
 */
export const manageBodyClass = (state: boolean): void => {
	if ( state ) {
		window.document.body.classList.add("fixed-scroll");
	} else {
		window.document.body.classList.remove("fixed-scroll");
	}
};
/*
 * Check is mobile
 * */
export const isMobile = () => (
	typeof window.matchMedia !== "undefined"
		? window.matchMedia("(max-width: 971px)").matches
		: false
);

export const delay = (ms: number): any => new Promise((res: any) => setTimeout(res, ms));

/* tslint:disable */
export const parseOrdinal = (value: number): string => {
	try {
		if ( value === 0 ) {
			return value + "";
		}
		const suffix = [ "th", "st", "nd", "rd" ];
		const v = value % 100;
		return value + ( suffix[ ( v - 20 ) % 10 ] || suffix[ v ] || suffix[ 0 ] );
	} catch ( e ) {
		console.log(e);
		return "";
	}
};
/* tslint:enable */

export const navigateToDraw = () => {
	window.location.assign('https://www.nrl.com/draw/')
};

export const isOFFSeason = () => {
	return process.env.REACT_APP_OFF_SEASON === 'true';
};
