import { observer } from "mobx-react";
import * as React from "react";
import styled, { css } from "styled-components";
import theme from "../../../assets/css/theme";
import { slideUp } from "../../../components";
import { Match as TMatch } from "../../../modules/stores/models/model.match";
import DrawContent from "./Match/DrawContent";
import MatchData from "./Match/MatchData";
import Squad from "./Match/Squad";
import { IRootStore, MobxContext } from '../../../modules/stores';
import sportsbet from '../../../assets/img/sportsbet.svg'

const MatchWrapper = styled.div<{ is_hover: boolean }>`
	box-shadow: 0 0 14px 0 rgba(0,0,0,0.1);
	background-color: #FFFFFF;
	margin-bottom: 0.675em;
	font-family: ${theme.base_font};

	animation: ${slideUp} 0.3s ease-in;

	a {
	  text-decoration: none;
	}

	${({ is_hover }) => is_hover && css`
		outline: 1px solid red;
	`}
`;

const DrawTagLine = styled.div`
	font-size: 14px;
	color: #595959;
	text-align: center;
`;

const FixtureWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #E8E8E8;
	position: relative;
`;

const OddsLine = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	background-color: #024995;
	color: #FFFFFF;
	font-size: 14px;
	font-weight: bold;
	height: 30px;
	width: 100%;
	
	> a{
		text-decoration: none;
	}
`;

const OddsLogo = styled.img`
	width: 68px;
	height: 15px;
	font-style: italic;
	font-weight: bold;
`;

interface IProps {
	match: TMatch;
}

type Props = IProps;

@observer
class MatchComponent extends React.Component<Props> {

	public static contextType = MobxContext;
	public context!: React.ContextType<typeof MobxContext>;

	public get injected() {
		return this.context as IRootStore;
	}

	public get draw_line() {
		const { match } = this.props;

		return (
			<DrawTagLine>
				<DrawContent match={match} />
			</DrawTagLine>
		);
	}

	public get odds_line() {
		const { match: { home_squad_odds, away_squad_odds } } = this.props;

		return (
			// eslint-disable-next-line no-template-curly-in-string
			<a target="_blank" rel="noreferrer noopener" href="https://ad.doubleclick.net/ddm/trackclk/N7629.128652.NRL/B25296542.295375729;dc_trk_aid=489026239;dc_trk_cid=142873052;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=">
				<OddsLine>

					${home_squad_odds.toFixed(2)}

					<OddsLogo src={sportsbet} alt="To make the Top 8" />
					${away_squad_odds.toFixed(2)}
				</OddsLine>
			</a>);

	}

	public render(): React.ReactNode {
		const { match } = this.props;
		const is_show_draw = match.home_squad_id && match.away_squad_id;
		const { ladder } = this.injected;

		return match ? (
			<MatchWrapper is_hover={match.is_hover}>
				<FixtureWrapper>
					<Squad
						match={match}
						squad_id={match.home_squad_id}
					/>
					<MatchData
						match={match}
					/>
					<Squad
						match={match}
						squad_id={match.away_squad_id}
					/>
				</FixtureWrapper>
				{is_show_draw ? this.draw_line : null}
				{ladder.is_switch_to_odds && !match.isComplete && this.odds_line}
			</MatchWrapper>
		) : null;
	}
}

export const Match = MatchComponent;
export default Match;
