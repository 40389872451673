import * as _ from "lodash";
import { uniqBy } from "lodash";
import { observable, when, action, computed, toJS } from "mobx";
import CoreCollection from "../../core/collection";
import Api from "../../utils/Api";
import Storage from "../../utils/Storage";
import { LadderModel } from "../models/model.ladder";
import { Match } from "../models/model.match";
import { Picks, IPick } from "../models/model.picks";
import { Round } from "../models/model.round";

import { Rounds } from "./collection.rounds";

// const IS_ODDS_DISABLED = process.env.REACT_APP_HIDE_ODDS === 'true';

const ADULT_AGE = 18;

/**
 * Ladder collection, stores colection by squad
 */
export class Ladder extends CoreCollection<LadderModel> {
	/**
	 * API method for request
	 */
	public get api() {
		return Api.JSON.ladder;
	}

	/**
	 * Model for collection
	 */
	public get model() {
		return LadderModel;
	}

	/**
	 * Returns sorted array of squads
	 */
	@computed
	public get sortedItems() {
		const squads = this.getItems();
		const sorted = squads.sort(Ladder.sort);
		const teams = _.orderBy(sorted, 'desc');
		return teams;

	}

	/**
	 * Returns sorted array of squads
	 */
	@computed
	public get ladderByPosition() {
		const squads = this.getItems();
		const ladderObj = squads.sort(Ladder.sort).reduce(
			(obj, team, teamIndex) => ({
				...obj,
				[teamIndex + 1]: team.id,
			}),
			{}
		);
		return JSON.stringify(ladderObj);
	}

	/**
	 * returns current favorite squad.
	 */
	@computed
	public get favorite_team() {
		const items = this.getItems();
		return _.find(items, _.matches({ is_favorite: true }));
	}

	/**
	 * Simple sorting
	 */
	public static sort(a: LadderModel, b: LadderModel) {
		/**
		 * sort by points
		 * if points are equal
		 * update to sort by Win in WDL
		 */

		if (a.points > b.points) {
			return -1;
		}
		if (b.points > a.points) {
			return 1;
		}

		const win_a = _.split(a.WDL, "-")[0];
		const win_b = _.split(b.WDL, "-")[0];
		if (win_a > win_b) {
			return -1;
		}

		if (win_b > win_a) {
			return 1;
		}

		if (a.margin > b.margin) {
			return -1;
		}
		if (b.margin > a.margin) {
			return 1;
		}

		return 0;
	}

	/**
	 * Increase for each of squads Form values
	 */
	public static setForm(match: Match, ladder_model: LadderModel) {
		if (_.eq(match.away_score, match.home_score)) {
			ladder_model.incrementFormDraw();
			return;
		}

		if (_.eq(match.away_squad_id, ladder_model.id)) {
			if (_.lt(match.away_score, match.home_score)) {
				ladder_model.incrementFormLoss();
				return;
			}
			ladder_model.incrementFormWon();
			return;
		}

		if (_.eq(match.home_squad_id, ladder_model.id)) {
			if (_.lt(match.away_score, match.home_score)) {
				ladder_model.incrementFormWon();
				return;
			}
			ladder_model.incrementFormLoss();
			return;
		}
	}

	/**
	 * Increase for each of squads Form values by Picks
	 */
	public static setFormByPicks(pick: IPick, ladder_model: LadderModel) {
		if (pick.is_final) {
			return;
		}

		if (_.eq(pick.squad_id, ladder_model.id)) {
			ladder_model.incrementFormWon();
			return;
		}

		if (pick.is_draw) {
			ladder_model.incrementFormDraw();
			return;
		}

		ladder_model.incrementFormLoss();
	}

	@observable
	public actual_ladder: LadderModel[] = [];
	@observable public is_switch_to_current: boolean = false;
	/**
	 * NLP22-12 default showing odds to true
	 * also removed odds check in src/modules/stores/index.ts
	 */
	@observable public is_switch_to_odds: boolean = false;
	@observable public is_favorites_fixed: boolean = true;
	// public is_odds_disabled: boolean = IS_ODDS_DISABLED;

	constructor() {
		super();

		/**
		 * Waiting for data from BE
		 */
		when(
			() => !this.isEmpty,
			() => this.setActualLadder().preFillFavorite()
		);
	}

	/**
	 * Store actual ladder
	 */
	public setActualLadder() {
		this.actual_ladder = JSON.parse(JSON.stringify(this.getItems())).sort(
			Ladder.sort
		);
		return this;
	}

	/**
	 * Check if user saved own favorite team. Set it into variable
	 */
	@action
	public preFillFavorite() {
		const selected_favorite = parseInt(
			Storage.get("favorite_club") || "",
			10
		);
		const isLoggedIn = Storage.get("is_authorized");
		if (!selected_favorite && this.actual_ladder && isLoggedIn) {
			const first_team_in_ladder = _.head(toJS(this.actual_ladder));
			if (first_team_in_ladder) {
				const default_favourite = first_team_in_ladder.id;
				this.setFavorite(default_favourite, false);
			}
			return this;
		}
		this.setFavorite(selected_favorite, false);
		return this;
	}

	/**
	 * Calculate BYE for each squad
	 */
	public calculateBye(rounds: Rounds, picks: Picks) {
		const rounds_items = [...rounds.completedRounds, ...rounds.activeRounds];
		const roundsWithPicks = rounds.getItems().filter(round => {
			const object = picks.byRoundID(round.id);
			const picksLength = Object.keys(object).length;
			return round.matches.length === picksLength && round.status !== 'complete';
		})
		// const array_to_use = uniqBy([...rounds_items, ...roundsWithPicks], 'id')

		const ladder = this.getItems();
		const hasZeroByeCount = ladder.some(element => element.bye_count === 0);
		const  array_to_use = hasZeroByeCount ? uniqBy([...rounds_items, ...roundsWithPicks], 'id') : roundsWithPicks;
		array_to_use.forEach((round: Round) => {
			const { bye_squads } = round;
			ladder.forEach((item: LadderModel) => {
				if (_.includes(bye_squads, item.id)) {
					// if (!item.bye_count) {
						// item.incrementBye();
					// }
					item.incrementBye();
					if (round.status !== 'complete' && round.status !== 'active') {
						// ladder from BE accounts for bye in points field
						// if the match has completed
						// if the tipped round isnt complete then we need to manually
						// adjust
						item.incrementPoints()
					}
				}
			});
		});

		return this;
	}

	/**
	 * Calculate BYE for each squad at the beginning
	 */
	public calculateByeInit(rounds: Rounds, picks: Picks) {
		const rounds_items = [...rounds.completedRounds, ...rounds.activeRounds];
		const roundsWithPicks = rounds.getItems().filter(round => {
			const object = picks.byRoundID(round.id);
			const picksLength = Object.keys(object).length;
			return round.matches.length === picksLength && round.status !== 'complete';
		})
		const array_to_use = uniqBy([...rounds_items, ...roundsWithPicks], 'id')


		const ladder = this.getItems();
		array_to_use.forEach((round: Round) => {
			const { bye_squads } = round;
			ladder.forEach((item: LadderModel) => {
				if (_.includes(bye_squads, item.id)) {
					// if (!item.bye_count) {
						// item.incrementBye();
					// }
					item.incrementBye();
					if (round.status !== 'complete') {
						// ladder from BE accounts for bye in points field
						// if the match has completed
						// if the tipped round isnt complete then we need to manually
						// adjust
						item.incrementPoints()
					}
				}
			});
		});

		return this;
	}

	/**
	 * Sets markers for each squads.
	 * 0 - draw
	 * 1 - grow
	 * -1 - down
	 */
	@action
	public setChangesPositions() {
		const squads = this.getItems();
		const sorted_squads = squads.sort(Ladder.sort);
		sorted_squads.forEach((squad: LadderModel, index: number) => {
			const prev_index = this.actual_ladder.findIndex(
				(squad_prev: LadderModel) => squad_prev.id === squad.id
			);
			squad.setPosition(index + 1);

			if (_.eq(prev_index, index) || !~prev_index) {
				squad.ladderIndex(0);

				return;
			}
			if (_.lt(prev_index, index)) {
				squad.ladderIndex(-1);
				return;
			}

			if (_.gt(prev_index, index)) {
				squad.ladderIndex(1);
			}
		});

		return this;
	}

	/**
	 * Set last 5 matches for each squads.
	 * Won or loss
	 */
	public calculateLast5ByPicks(rounds: Rounds, picks?: Picks) {
		const matches_scheduled = rounds.scheduledAllMatches;
		const playing_matches = rounds.playingAllMatches;
		const matches = [...playing_matches, ...matches_scheduled];

		const items = this.getItems();

		items.forEach((item: LadderModel) => {
			item.resetForm();

			const matches_with_picks = picks
				? _.filter(matches, (match: Match) => {
					const is_squad_in_match =
						_.eq(match.away_squad_id, item.id) ||
						_.eq(match.home_squad_id, item.id);

					return is_squad_in_match && !!picks.getByID(match.id);
				})
				: [];

			const matches_completed = rounds.getCompletedMatchesBySquadID(
				item.id
			);
			const LAST_FIVE_MATCHES = 5;

			const last_5_matches = _.takeRight(
				[...matches_completed, ...matches_with_picks],
				LAST_FIVE_MATCHES
			);
			_.forEach(last_5_matches, (match: any) => {
				if (match.isComplete) {
					Ladder.setForm(match, item);
				} else if (picks) {
					const pick = picks.getByID(match.id);

					Ladder.setFormByPicks(pick, item);
				}
			});
		});
		return this;
	}

	/**
	 * Changes state of current favorite squad
	 */
	public setFavorite(squad_id: number, toggle_state = true) {
		const squad = this.getLadderById(squad_id);
		const state = parseInt(Storage.get("favorite_club") || "", 10);

		const new_state = toggle_state ? !_.eq(state, squad_id) : true;

		if (squad) {
			if (new_state) {
				const prev_squad = this.getLadderById(state);
				if (prev_squad) {
					prev_squad.setFavorite(false);
				}
				Storage.set("favorite_club", squad_id);
			} else {
				// Storage.remove('favorite_club');
			}

			squad.setFavorite(new_state);
		}
	}

	/**
	 * Returns squad by ID
	 */
	@observable
	public getLadderById(id: number) {
		const squads = this.getItems();
		return squads.find((item: LadderModel) => item.id === id);
	}

	/**
	 * Calculate some scores for home and away squad depends on user's picks
	 */
	public calculateScores({ home_squad, away_squad, match, pick }: any) {
		if (pick && !pick.is_final) {
			home_squad.incrementGames();
			away_squad.incrementGames();
			if (pick.is_draw) {
				home_squad.incrementPointsDraw();
				home_squad.incrementDraw();

				away_squad.incrementPointsDraw();
				away_squad.incrementDraw();
				return;
			}

			if (_.eq(match.home_squad_id, pick.squad_id)) {
				home_squad.incrementPoints();
				home_squad.incrementMargin(pick);
				home_squad.incrementWon();

				away_squad.incrementLost();
				away_squad.decrementMargin(pick);
			}

			if (_.eq(match.away_squad_id, pick.squad_id)) {
				home_squad.decrementMargin(pick);
				home_squad.incrementLost();

				away_squad.incrementPoints();
				away_squad.incrementMargin(pick);
				away_squad.incrementWon();
			}
		}
	}

	/**
	 * Calculate ladder squads by each pick
	 */
	public calculateByPicks(matches: Match[], picks: Picks) {
		matches.forEach((match: Match) => {
			const home_squad = this.getLadderById(match.home_squad_id);
			const away_squad = this.getLadderById(match.away_squad_id);

			if (home_squad && away_squad && match.status !== "complete") {
				const pick = picks.getByID(match.id);
				this.calculateScores({
					home_squad,
					away_squad,
					match,
					pick,
				});
			}
		});

		return this;
	}

	/**
	 * Switch current ladder to actual or vice versa
	 */
	@action
	public changeLadderState(current_ladder: boolean) {
		this.is_switch_to_current = current_ladder;
	}

	/**
	 * Switch current ladder to actual or vice versa
	 */
	@action
	public changeLadderOddsState(current_odds: boolean) {
		this.is_switch_to_odds = current_odds;
	}

	/**
	 * Checks which of components will be fixed
	 * FavoriteMobileTeam
	 * YourSelections
	 * @see Ladder
	 */
	@action
	public changeFixedPosition(fixed: boolean) {
		this.is_favorites_fixed = fixed;
	}

	/**
	 * TODO add description
	 * @ignore
	 */
	public checkOdds(age: number) {
		this.changeLadderOddsState(_.gte(age, ADULT_AGE));
		return this;
	}
}
