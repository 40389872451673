import styled, { css } from "styled-components";
import media from "../../assets/css/media";
import selected from "../../assets/img/icons/selected.svg";
import selected_draw from "../../assets/img/icons/selected_draw.svg";
import { flip } from "../keyFrames";

interface IPickMarker {
	picked: boolean;
	is_draw?: boolean;
	is_show_empty?: boolean;
	size?: number;
}

export const PickMarker = styled.div<IPickMarker>`
	width: ${({ size }) => size || 32}px;
	min-width: ${({ size }) => size || 32}px;
	height: ${({ size }) => size || 32}px;

	flex-basis: ${({ size }) => size || 32}px;
	margin: 0 1em;
	border-radius: 50%;
	cursor: pointer;
	background: #fff;
	${({ picked, is_draw, is_show_empty }) => picked || is_draw ? css`
		background: url("${is_draw ? selected_draw : selected}") center no-repeat;
		background-size: 100%;
		animation: ${flip} 0.2s ease;
	` : is_show_empty ? css`
		border: 2px solid #E8E8E8;
	` : ``};

	@media (max-width: ${media.desktop}) {
		width: 20px;
		height: 20px;
		min-height: 20px;
		min-width: 20px;
		flex-basis: 20px;
		margin: 0 0.5em;
	}

`;
