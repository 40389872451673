import * as React from "react";
import styled from "styled-components";
import "react-toggle/style.css";
import media from "../../assets/css/media";
import theme from "../../assets/css/theme";

const SwitchToggleLabel = styled.label`
	font-size: 14px;
	line-height: 1.5;
	color: #767676;
	margin-right: 1em;
	cursor: pointer;
	text-transform: initial;
	font-family: ${theme.base_font};
	letter-spacing: 0.43px;
	font-weight: normal;

	@media (max-width: ${media.desktop}) {
		font-size: 12px
	}
`;

const SwitchToggleStyled = styled.div`
	display: flex;
	align-items: center;
	margin-right: 1em;
	transition: all 0.3s ease;

	.custom-checkbox {
		position: relative;
		cursor: pointer;
		height: 20px;
		width: 50px;
		border-radius: 100px;
		background: #E8E8E8;
		transform: translate3d(0,0,0);

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 50%;
			border-radius: 50%;
			display: block;
			height: 30px;
			width: 30px;
			background-color: #FFFFFF;
			box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
			transform: translateY(-50%);
			transition: all 0.3s ease;
		}
	}

	input {
		visibility: hidden;
		width: 0;
		margin: 0;

		&:checked + label {
			background: #00CF5D;

			&:before {
				transition: all 0.3s ease;
				right: 0;
				left: auto;
			}
		}
	}
	
	&.ladder_odds{
		input:checked + label{
			background: #3BA6DE;
		}
	}
`;

interface IProps {
	id: string;
	name: string;
	checked?: boolean;
	onChange?: (e: React.SyntheticEvent<HTMLInputElement>) => void;
	label?: string;
	className?: string;
	disabled?: boolean;
	value?: boolean;
}

export const SwitchToggle = ({
	                             name,
	                             id,
	                             checked,
	                             value,
	                             onChange,
	                             label,
	                             className,
	                             disabled = false,
                             }: IProps) => (
	<SwitchToggleStyled id={`id-${id}`} className={className}>
		{label ? (<SwitchToggleLabel
			htmlFor={id}>{label}</SwitchToggleLabel>) : null}

		<input type="checkbox"
		       defaultChecked={checked}
		       checked={value}
		       id={id}
		       name={name}
		       onChange={onChange}
		       disabled={disabled}
		       readOnly={true}
		/>
		<label className="custom-checkbox" htmlFor={id}/>

	</SwitchToggleStyled>
);

export default SwitchToggle;
