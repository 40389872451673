interface IMediaPoints {
	desktop: string;
	desktop_min: string;
	mobile: string;
}

export const media: IMediaPoints = {
	desktop    : "960px",
	desktop_min: "959px",
	mobile     : "480px",
};

export default media;
