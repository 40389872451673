import styled from "styled-components";
import { media } from "../../assets/css/media";
import RegularButton from "./RegularButton";

export const SecondaryButton = styled(RegularButton)`
	background: ${(props) => props.theme.primary};
	border: 1px solid ${(props) => props.theme.primary};
	color: white;

	@media (max-width: ${media.desktop}) {
			margin: 1em auto;
	}
`;

export default SecondaryButton;
