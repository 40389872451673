import {
	observable,
	action,
	computed,
} from "mobx";


/**
 * Match model
 */
export class Match {

	/**
	 * @ignore
	 */
	@computed
	public get isComplete() {
		return !!~[ Match.match_statuses.complete ].indexOf(Match.match_statuses[ this.status ]);
	}

	/**
	 * @ignore
	 */
	@computed
	public get isSchedule() {
		return !!~[ Match.match_statuses.scheduled ].indexOf(Match.match_statuses[ this.status ]);
	}

	/**
	 * @ignore
	 */
	@computed
	public get isPlaying() {
		return !!~[ Match.match_statuses.playing ].indexOf(Match.match_statuses[ this.status ]);
	}

	/**
	 * @ignore
	 */
	@computed
	public get isDraw() {
		return this.isComplete && this.away_score === this.home_score;
	}

	/**
	 * Returns winner id between squads in match
	 */
	@computed
	public get winnerID() {

		if ( this.away_score === this.home_score ) {
			return 0;
		}

		if ( this.away_score > this.home_score ) {
			return this.away_squad_id;
		}

		return this.home_squad_id;
	}

	/**
	 * Check if match has away and home squad
	 */
	@computed
	public get hasSquads(): boolean {
		return Boolean(this.away_squad_id && this.home_squad_id);
	}

	public static match_statuses = {
		complete : 1,
		scheduled: 2,
		playing  : 3,
	};

	public static default_draw_index = 4;
	public static draw_items = [ "1", "2", "4", "8", "12", "18", "24", "30", "38", "48" ];
	public id: number = 0;
	public round: number = 0;
	public match: number = 0;
	public home_squad_id: number = 0;
	public home_squad_odds: number = 0;
	public home_squad_odds_id: number = 0;
	public away_squad_id: number = 0;
	public away_squad_odds: number = 0;
	public away_squad_odds_id: number = 0;
	public venue_id: number = 0;
	public status: string = "";
	public date: string = "";
	public is_first: number = 0;
	public is_last: number = 0;
	public is_match_day: number = 0;
	public is_margin_game: number = 0;
	public hashtag: string = "";
	public venue_name: string = "";
	public home_squad_name: string = "";
	public away_squad_name: string = "";
	public home_score: number = 0;
	public away_score: number = 0;
	public is_final: boolean = false;
	public eliminated: number = 0;
	public is_postponed: number = 0;

	@observable public is_hover: boolean = false;

	constructor(data: Partial<Match>) {
		Object.assign(this, data);
	}

	/**
	 * Hover state of selections
	 */
	@action.bound
	public setHoverEffect(state: boolean): void {
		this.is_hover = state;
	}

	/**
	 * @ignore
	 */
	public hasSquadByID(squad_id: number): boolean {
		return [this.away_squad_id, this.home_squad_id].includes(squad_id);
	}

	/**
	 * @ignore
	 */
	public isHomeSquad(squad_id: number): boolean {
		return [this.home_squad_id].includes(squad_id);
	}
}
