import { observer } from "mobx-react";
import React, { Component, createRef } from "react";
import styled from "styled-components";
import media from "../assets/css/media";
import { Content, AdServing, Analitics } from "../components";
import { MobxContext, IRootStore } from "../modules/stores";
import ByeSquads from "./LadderPredictor/ByeSquads";
import FinalBracket from "./LadderPredictor/FinalBracket";
import FinalResult from "./LadderPredictor/FinalResult";
import Fixture from "./LadderPredictor/Fixture";
import FixtureTagLine from "./LadderPredictor/FixtureTagLine";
import Ladder from "./LadderPredictor/Ladder";
import MissedRounds from "./LadderPredictor/MissedRounds";
import YourSelections from "./LadderPredictor/YourSelections";

const ContentLadder = styled(Content)`
	display: flex;
	justify-content: space-between;
	section {
		flex: 1 1 100%;
		max-width: 49%;
	}

	@media (max-width: ${media.desktop}) {
		flex-direction: column;
		section {
			max-width: 100%;
			flex: 1 1 100%;
		}
	}
`;

const YourSelectionsWrapper = styled.span``;

interface IFinalBracketsWrapper {
	is_hidden: boolean;
}

const FinalBracketsWrapper = styled.span<IFinalBracketsWrapper>`
	@media (max-width: ${media.desktop}) {
		display: ${({ is_hidden }) => is_hidden && "none"};
	}
`;

// const RotateIcon = styled.div`
// 	display: none;
// 	position: fixed;
// 	right: 0;
// 	top: 50%;
// 	transform: translateY(-50%);
// 	z-index: 9;
// 	@media (max-width: ${media.desktop}) {
// 		display: block;
// 	}
//
// 	@media (orientation: portrait){
// 		>:first-child{
// 			display: none;
// 		}
// 	}
//
// 	@media (orientation: landscape){
// 		>:last-child{
// 			display: none;
// 		}
// 	}
// `;

@observer
class LadderPredictor extends Component {
	public state = {
		selected_round: null,
	};

	static contextType = MobxContext;
	context!: React.ContextType<typeof MobxContext>;

	public get injected() {
		return this.context as IRootStore;
	}

	public fixture_ref: React.RefObject<HTMLDivElement>;
	public ladder_ref: React.RefObject<HTMLDivElement>;
	public final_brackets_hidden: boolean = Boolean(
		parseInt(process.env.REACT_APP_HIDE_MOBILE_FINAL_BRACKETS || "", 10)
	);

	constructor(props: any) {
		super(props);

		this.fixture_ref = createRef();
		this.ladder_ref = createRef();
	}

	public get ladderClassName() {
		const {
			ladder: { is_favorites_fixed },
		} = this.injected;

		let classname = !is_favorites_fixed ? "favourite-visible" : "";
		const {
			rounds: { getCurrentRound: round },
		} = this.injected;
		if (!this.final_brackets_hidden && round && round.is_final) {
			classname = classname + " hidden-mobile-landscape";
			return classname;
		}
		return classname;
	}
	public get sectionClassName() {
		const {
			ladder: { is_favorites_fixed },
		} = this.injected;

		let classname = !is_favorites_fixed ? "favourite-visible" : "";
		return classname;
	}

	// public get rotateIcon() {
	// 	const { rounds: { getCurrentRound: round } } = this.injected;
	//
	// 	if (round && round.is_final)
	// 		return (
	// 			<RotateIcon>
	// 				<Icon size={60} name={'rotate-portrait'}/>
	// 				<Icon size={60} name={'rotate-landscape'}/>
	// 			</RotateIcon>
	// 		);
	//
	// 	return null;
	// }

	render() {
		const {
			rounds: { getCurrentRound: round },
		} = this.injected;

		return (
			<>
				{round && <Analitics />}
				<Content className={this.ladderClassName}>
					<AdServing slot="slot1" round={round} />
					<MissedRounds />
				</Content>
				<ContentLadder className={this.ladderClassName}>
					<section>
						<FixtureTagLine
							refElem={this.fixture_ref}
							with_title={true}
						/>
						<Fixture />
						<ByeSquads />
						<YourSelections />
						<FixtureTagLine with_title={false} />
					</section>
					<section className={this.sectionClassName}>
						<Ladder
							refOnClick={this.ladder_ref}
							refElem={this.ladder_ref}
						/>
						<AdServing slot="slot2" />

						<YourSelectionsWrapper className="hidden-desktop">
							<YourSelections refOnClick={this.fixture_ref} />
						</YourSelectionsWrapper>
					</section>
				</ContentLadder>

				<FinalBracketsWrapper
					className="test"
					is_hidden={this.final_brackets_hidden}
				>
					<FinalBracket />
				</FinalBracketsWrapper>

				<div className={this.ladderClassName}>
					<FinalResult />
				</div>
			</>
		);
	}
}

export default LadderPredictor;
