import * as _ from 'lodash';
import { observer } from "mobx-react";
import React, { Component } from 'react';
import styled from "styled-components";
import media from "../../assets/css/media";
import {
	MobxContext,
	IRootStore
} from "../../modules/stores";
import { Round } from "../../modules/stores/models/model.round";
import FinalMatches from './FinalBracket/FinalMatches';
import { Content } from '../../components';

const RoundWrapper = styled.div`
	display: flex;
	flex: 1;
	margin-right: 2em;
	flex-direction: column;
	&:last-child {
		margin-right: 0;
	}
	
	@media (max-width: ${media.desktop}) {
		margin-right: 10px;
		&:last-child {
			margin-right: 0;
		}
	}
`;

const FinalBracketLine = styled.div`
	padding: 3.75em 0;
	background-color: #ECECEC;
	@media (max-width: ${media.desktop}) {
		padding: 0 1em;
	}
`;

const ContentFlex = styled(Content)`
	display: flex;
	justify-content: space-between;
	
	@media (max-width: ${media.desktop}) {
		margin: 0;
	}
`;

@observer
class FinalBracket extends Component {
	static contextType = MobxContext;
	context!: React.ContextType<typeof MobxContext>;

	public get injected() {
		return this.context as IRootStore;
	}

	render() {
		const { rounds: { getCurrentRound: round, finalsRounds: rounds } } = this.injected;

		if ( round && !round.is_final ) {
			return null;
		}
		return (
			<FinalBracketLine className="hidden-mobile-portrait">
				<ContentFlex>
					{_.map(rounds, (round: Round, index: number) => (
							<RoundWrapper key={index}>
								<FinalMatches
									round={round}
									matches={round.final_matches}
								/>
							</RoundWrapper>
						)
					)}
				</ContentFlex>

			</FinalBracketLine>
		);
	}
}

export default FinalBracket;
