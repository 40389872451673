import { keyframes } from "styled-components";

export const clickWawe = keyframes`
	from {
	   	width: 100%;
		height: 40px;
		opacity: 0.35;

	}
	to {
	    width: 120%;
		height: 200px;
		margin-left: -80px;
		margin-top: -80px;
		opacity: 0.0;
	}
`;
