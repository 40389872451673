import styled from "styled-components";
import { padding_mobile_css } from "../StylesUtils";

export const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	margin: 1em auto;
	section {
		max-width: 600px;
		${padding_mobile_css}
	}
`;
