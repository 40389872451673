import * as _ from "lodash";
import {
	observable,
	action,
	toJS,
	computed,
} from "mobx";
import Storage from "../../utils/Storage";
import Api from "../../utils/Api";


interface IFormData {
	nrl_account_id: number;
	nrl_club: number;
	round: number;
	ladder: string,
	premier_winner: number;
}

interface IPickActionDraw {
	id: number;
	round_id: number;
	draw_index: number;
	is_draw: boolean;
	is_final: boolean;
	is_grand_final: boolean;
	form_data: IFormData | null;
}

/*
interface ILadderFormData {
	[index: number]:number
}
*/

export interface IPick extends IPickActionDraw {
	squad_id: number;
}

// tslint:disable-next-line
export type TPicks = {
	[key: number]: IPick;
}

export class Picks {

	@observable public picks: TPicks = {};

	/**
	 * @ignore
	 */
	@computed
	public get isEmpty(): boolean {
		return _.isEmpty(this.picks);
	}

	constructor() {
		this.picks = JSON.parse(Storage.get("picks") || "{}");

	}

	/**
	 * @ignore
	 */
	@action
	public savePicks(pick: IPick) {
		this.picks = {
			...this.picks,
			[pick.id]: {
				...this.picks[pick.id],
				...pick,
			},
		};
		this.saveToStorage();

		if (pick.is_grand_final && pick.form_data) {
			Api.Predictions.save(pick.form_data)
		}
	}

	/**
	 * @ignore
	 */
	@action
	public makeDraw(pick: IPickActionDraw) {

		this.picks = {
			...this.picks,
			[pick.id]: {
				...this.picks[pick.id],
				draw_index: pick.draw_index,
				is_draw: pick.is_draw,
				round_id: pick.round_id,
				is_final: pick.is_final,
			},
		};

		this.saveToStorage();
	}

	/**
	 * @ignore
	 */
	@action
	public setPicksAutoFill(picks: TPicks) {
		_.each(picks, (pick: IPick, key: string) => {
			if (!this.picks[key]) {
				this.picks = {
					...this.picks,
					[key]: pick,
				};
			}
		});

		this.saveToStorage();
	}

	/**
	 * @ignore
	 */
	@action
	public deletePicks(ids: number[] | string[]) {

		const picks = {
			...this.picks,
		};
		_.each(ids, (id: any) => {
			delete picks[id];
		});

		this.picks = {
			...picks,
		};

		this.saveToStorage();
	}

	/**
	 * @ignore
	 */
	@action
	public clearAll() {
		this.picks = {};
		this.saveToStorage();
	}

	/**
	 * @ignore
	 */
	@observable
	public byRoundID(id: number) {

		return _.pickBy(this.picks, _.matches({ round_id: id }));
	}

	/**
	 * @ignore
	 */
	@observable
	public getByID(id: number): IPick {
		return this.picks[id];
	}

	/**
	 * @ignore
	 */
	private saveToStorage() {
		Storage.set("picks", JSON.stringify(toJS(this.picks)));
	}

}
