import styled from "styled-components";
import media from "../../assets/css/media";
import theme from "../../assets/css/theme";

export const Title = styled.div<{ hide_mobile?: boolean }>`
	font-family: ${theme.base_font};
	font-weight: 500;
	letter-spacing: 1px;
	color: #222222;
	font-size: 1.625em;
	text-transform: uppercase;
	margin-bottom: 30px;
	margin-top: 1em;
	display: flex;
	justify-content: space-between;
	span {
		color: #00CF5D;
	}

	@media(max-width: ${media.desktop}) {
		display: ${({ hide_mobile }) => hide_mobile && "none"};
		font-weight: 500;
		letter-spacing: 0.75px;
		font-size: 12px;
		margin-bottom: 1em;
		padding-top: 0.5em;
		span {
			margin-left: 0.5em;
		}
	}

	p {
		display: flex;
		align-items: center;
		font-size: 20px;
		
		@media(max-width: ${media.desktop}) {
			font-size: 12px;
		}
	}
`;
