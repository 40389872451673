import "core-js";
import React from "react";
import { render } from "react-dom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import core from "./assets/css/core";
import theme from "./assets/css/theme";

import {
	ScrollToTop,
	ErrorBoundary,
	LoadingSpinner,
	Header,
	Footer,
	NotAuthRoute,
	AdServing,
} from "./components";
import RootStore, { MobxContext } from "./modules/stores";
import LadderPredictor from "./pages/LadderPredictor";

import NotFound from "./pages/NotFound";

const GlobalStyle = createGlobalStyle`${core}`;

render(
	<MobxContext.Provider value={RootStore}>
		<ErrorBoundary>
			<GlobalStyle theme={theme} />
			<BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
				<ScrollToTop>
					<React.Suspense fallback={<LoadingSpinner />}>
						<Header />
						<Switch>
							<NotAuthRoute
								path={"/ladder"}
								component={LadderPredictor}
							/>
							<Route exact path="/">
								<Redirect to="/ladder" />
							</Route>
							<Route exact path="/table">
								<Redirect to="/ladder" />
							</Route>
							<Route component={NotFound} />
						</Switch>
						<Footer />
						<AdServing slot="out_of_page" />
					</React.Suspense>
				</ScrollToTop>
			</BrowserRouter>
		</ErrorBoundary>
	</MobxContext.Provider>,

	document.getElementById("root")
);
