import * as _ from 'lodash';
import { observer } from "mobx-react";
import React, { Component } from 'react';
import styled, { css } from "styled-components";
import media from "../../../assets/css/media";
import {
	PickMarker,
	Image,
	Title
} from '../../../components';
import {
	MobxContext,
	IRootStore
} from "../../../modules/stores";
import { Rounds } from "../../../modules/stores/collections";
import { Match } from "../../../modules/stores/models/model.match";
import eliminated from "../../../assets/img/icons/eliminated.svg";
import { Round } from "../../../modules/stores/models/model.round";


const MatchesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
	
	${Title} {
		font-size: 16px;
		margin-bottom: 1em;
	}
`;
const MatchWrapper = styled.div<{ is_selected: boolean }>`
	background: #fff;
	padding: 0 0.25em;
	box-shadow: 0 0 14px 0 rgba(0,0,0,0.1);
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 80px;
	span {
		font-weight: bold;
		font-size: 20px;
		color: #222;
		flex: 1;
		text-align: center;
	}
	
	${({ is_selected }) => !is_selected && css`
		background: rgba(255,255,255,0.5);
		span {
			color: #E8E8E8;
		}
		
		img.none {
			max-height: 30px;
		}
	`};
	
	@media (max-width: ${media.desktop}) {
		height: 50px;
		
		span {
			font-size: 8px;
			max-width: 10px;
		}
		
		${PickMarker} {
			margin: 0 4px;
		}
	}
`;

const ImageWrapper = styled.div`
	max-width: 40px;
	max-height: 40px;
	height: 40px;
	margin: 0 auto 0.25em;
	display: flex;
	align-items: center;
	img {
		height: auto;
	}
	
	.blank {
		max-height: 30px;
	}

`;

const Squad = styled.div`
	flex: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const PickMarkerEliminated = styled(PickMarker)`
	background:url("${eliminated}") center no-repeat;
	background-size: 100%;
`;

interface IOwn {
	matches: Match[]
	round: Round
}

const is_disabled_final_features = Boolean(process.env.REACT_APP_DISABLE_FINAL_BRACKET_ACTIONS);



@observer
class FinalMatches extends Component<IOwn> {
	constructor(props: IOwn) {
		super(props);
		_.bindAll(this, [
			'makePick'
		]);
	}

	public getPickedMarker(match: Match, squad_id: number) {
		const { picks, rounds: { eliminated_squads } } = this.injected;
		const pick = picks.getByID(match.id);
		if(match.isComplete) {
			const is_match_eliminated = eliminated_squads[match.id];
			const won_id = match.winnerID;

			if(_.eq(won_id, squad_id)) {
				return <PickMarker
					onClick={_.partial(this.makePick, squad_id, match)}
					size={20}
					is_show_empty={!is_disabled_final_features}
					picked={true}/>
			} else {
				return is_match_eliminated ?
				<PickMarkerEliminated picked={true} size={20}/> : null
			}

		}
		const is_elim = squad_id && _.eq(eliminated_squads[match.id], squad_id);

		if(is_elim && pick) {
			return <PickMarkerEliminated picked={true} size={20}/>
		}

		return pick ? (
			<PickMarker
				onClick={_.partial(this.makePick, squad_id, match)}
				size={20}
				is_show_empty={!is_disabled_final_features}
				picked={_.eq(pick.squad_id, squad_id)}/>
		) : null
	}

	static contextType = MobxContext;
	context!: React.ContextType<typeof MobxContext>;

	public get injected() {
		return this.context as IRootStore;
	}

	public render() {
		const { round, matches } = this.props;

		return (
			<MatchesWrapper>
				<Title>
					<div>{Rounds.final_names[ round.id ]} <span>/</span></div>
				</Title>
				{_.map(matches, (match: Match, index: number) => (
					<MatchWrapper
						key={index}
						is_selected={Boolean(match.home_squad_id && match.away_squad_id)}
					>
						<Squad>
							{this.getPickedMarker(match, match.home_squad_id)}
							<ImageWrapper>
								<Image squad_id={match.home_squad_id}/>
							</ImageWrapper>

						</Squad>

						<span>Vs</span>
						<Squad>
							<ImageWrapper>
								<Image squad_id={match.away_squad_id}/>
							</ImageWrapper>
							{this.getPickedMarker(match, match.away_squad_id)}
						</Squad>


					</MatchWrapper>
				))}
			</MatchesWrapper>
		);
	}



	private makePick(squad_id: number, match: Match) {
		if(is_disabled_final_features) {
			return;
		}

		const { picks, rounds: { getCurrentRound: round } } = this.injected;
		const pick = picks.getByID(match.id) || {};
		if ( !match.home_squad_id || !match.away_squad_id ) {
			return;
		}
		if ( picks && round ) {

			if ( pick && pick.squad_id === squad_id ) {
				picks.deletePicks([ pick.id ]);
				round.removePickMatchIDs(pick.id);
			} else {
				picks.savePicks({
					id        : match.id,
					squad_id,
					round_id  : match.round,
					draw_index: pick.draw_index || Match.default_draw_index,
					is_draw   : false,
					is_final  : round.is_final,
					is_grand_final: round.is_grand_final,
					form_data:{
						nrl_account_id: this.injected.user.nrl_account_id,
						nrl_club: this.injected.ladder.favorite_team ? this.injected.ladder.favorite_team.id:0,
						round: this.injected.rounds.todaysRoundID,
						ladder: this.injected.ladder.ladderByPosition,
						premier_winner: squad_id
					}
				});

				round.setPickMatchIDs(match.id);
			}

		}
	}

	// private sendPrediction() {

	// }
}

export default FinalMatches;