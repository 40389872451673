import * as _ from "lodash";
import {
	observable,
	computed,
	action,
} from "mobx";

import Storage from "../../utils/Storage";
import { Match } from "./model.match";
import { LadderModel } from "./model.ladder";

export const DOESNT_MATTER = "it_doesnt_matter";

/* tslint:disable */
const FINALS = {
	QF: {
		id             : 26,
		match_positions: [ [ 0, 3 ], [ 4, 7 ], [ 5, 6 ], [ 1, 2 ] ],
		match_counts   : 4,
		match_range: [1, 5]
	},
	SF: {
		id             : 27,
		match_counts   : 2,
		match_positions: [ [ 0, 3, 4, 7 ], [ 5, 6, 1, 2 ] ],
		match_range: [5, 7]
	},
	PF: {
		id             : 28,
		match_positions: DOESNT_MATTER,
		match_counts   : 2,
		match_range: [7, 9]
	},
	GF: {
		id             : 29,
		match_positions: DOESNT_MATTER,
		match_counts   : 1,
		match_range: [9, 999]

	},
};
/* tslint:enable */

export class Round {

	/**
	 * @ignore
	 */
	@computed
	public get isActual() {
		return !!~[
			Round.round_statuses.active,
			Round.round_statuses.scheduled,
		].indexOf(Round.round_statuses[ this.status ]);
	}

	/**
	 * @ignore
	 */
	@computed
	public get isSchedule() {
		return !!~[
			Round.round_statuses.scheduled,
		].indexOf(Round.round_statuses[ this.status ]);
	}

	/**
	 * @ignore
	 */
	@computed
	public get isActive() {
		return !!~[
			Round.round_statuses.active,
		].indexOf(Round.round_statuses[ this.status ]);
	}

	/**
	 * @ignore
	 */
	@computed
	public get isComplete() {
		return !!~[
			Round.round_statuses.complete,
		].indexOf(Round.round_statuses[ this.status ]);
	}


	/**
	 * Is full filed,
	 * checks based on filled_match_ids
	 */
	@computed
	public get isFullFilled() {
		const scheduled_matches = this.scheduledMatches;
		const has_squads = _.filter(scheduled_matches, (match: Match) =>
			match.hasSquads,
		);

		return _.isEmpty(has_squads) || _.eq(has_squads.length, this.filled_match_ids.length);
	}

	/**
	 * @ignore
	 */
	@computed
	public get isEmptyFill() {
		return !this.filled_match_ids.length;
	}

	/**
	 * @ignore
	 */
	@computed
	public get getMatches() {
		return this.isFinal ? this.final_matches : this.matches;
	}

	/**
	 * @ignore
	 */
	@computed
	public get completedMatches() {
		return this.getMatches.filter((match: Match) => match.isComplete);
	}

	/**
	 * @ignore
	 */
	@computed
	public get scheduledMatches() {
		return this.getMatches.filter((match: Match) => match.isSchedule);
	}

	/**
	 * @ignore
	 */
	@computed
	public get playingMatches() {
		return this.getMatches.filter((match: Match) => match.isSchedule);
	}

	/**
	 * @ignore
	 */
	@computed
	public get isFinal() {
		return this.is_final;
	}

	/**
	 * @ignore
	 */
	@computed
	public get isFinalByQF() {
		return _.eq(this.final_type, "QF");
	}

	/**
	 * @ignore
	 */
	@computed
	public get isFinalBySF() {
		return _.eq(this.final_type, "SF");
	}

	/**
	 * @ignore
	 */
	@computed
	public get isFinalByPF() {
		return _.eq(this.final_type, "PF");
	}

	/**
	 * @ignore
	 */
	@computed
	public get isFinalByGF() {
		return _.eq(this.final_type, "GF");
	}

	/**
	 * @ignore
	 */
	public get finalDefinisions() {
		return FINALS[ this.final_type ];
	}
	public static round_statuses = {
		complete : 1,
		scheduled: 2,
		active   : 3,
	};

	public id: number = 0;
	public status: string = "-";
	public end: string = "-";
	public bye_squads: number[] = [];
	public eliminated: number[] = [];
	@observable
	public matches: Match[] = [];

	@observable
	public actual_ladder: LadderModel[] = [];

	@observable	public final_matches: Match[] = [];
	public is_final: boolean = false;
	public is_grand_final: boolean = false;

	@observable public filled_match_ids: number[] = [];
	@observable public need_to_fill_ids = true;
	public final_type: string = "-";

	constructor(data: Partial<Round>) {
		Object.assign(this, data);
		this.matches = _.map(data.matches, (match: Match) => new Match(match));
	}

	/**
	 * @ignore
	 */
	@action
	public setAutoFillObject(ladder: any) {
		let result = {};
		/**
		 * TODO Refactoring
		 * Made it disabled because the refactoring is out of scope the migration process
		 */
		// tslint:disable-next-line:cyclomatic-complexity
		_.forEach(this.getMatches, (match: Match) => {
			const draw_item = _.random(0, Match.draw_items.length - 1);
			const pos_1 = ladder.getLadderById(match.home_squad_id);
			const pos_2 = ladder.getLadderById(match.away_squad_id);

			if ( match.away_squad_id && match.home_squad_id && pos_1 && pos_2) {
				const win_first = pos_1.points > pos_2.points;
				const win_position = pos_1.position > pos_2.position;
				const squad_by_position = win_position ? match.home_squad_id : match.away_squad_id;
				const squad_by_points = win_first ? match.home_squad_id : match.away_squad_id;
				const check_by_positioon = !pos_1.points && !pos_2.points;

				const side = check_by_positioon ? squad_by_position : squad_by_points
				result = {
					...result,
					[ match.id ]: {
						id        : match.id,
						round_id  : match.round,
						draw_index: draw_item,
						is_draw   : false,
						squad_id  : side
					},
				};
			}

		});

		return result;
	}

	/**
	 * @ignore
	 */
	@action
	public setPickMatchIDs(id: number) {
		if ( !_.includes(this.filled_match_ids, id) ) {
			this.filled_match_ids.push(id);
		}
	}

	/**
	 * @ignore
	 */
	@action
	public removePickMatchIDs(id: number) {
		this.filled_match_ids = _.reject(this.filled_match_ids, _.partial(_.eq, id));
	}

	/**
	 * @ignore
	 */
	@action
	public fillMatchIDS() {
		const matches = this.getMatches;
		this.filled_match_ids = [];

		const picks = JSON.parse(Storage.get("picks") || "{}");

		_.forEach(matches, ( (match: Match) => {
			if ( picks[ match.id ] && match.status === "scheduled" && match.hasSquads ) {
				this.filled_match_ids.push(match.id);
			}
		} ));
	}

	/**
	 * @ignore
	 */
	@action
	public setCurrentLadder(ladders: LadderModel[]) {
		this.actual_ladder = ladders;
	}

	/**
	 * @ignore
	 */
	@action
	public setHoverEffect(match: Match, state: boolean): void {
		match.is_hover = state;
	}

}
