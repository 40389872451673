import React from 'react';

import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { SecondaryButton } from '../components';


const NotFoundWrapper = styled.div`
	border-top: 8px solid #272935;
	border-bottom: 8px solid #272935;   
	padding: 10px 0 20px;
	width: 210px;
	font-family: ${props => props.theme.base_bold_font};
	margin: 2em auto;
	text-align: center;
	span{
	    font-size: 7em;
	}
	
	p {
	    font-size: 1.5em;
	}
   
`;

const Title = styled.div`
	font-size: 16px;
	text-align: center;
	padding: 1em;
`;

const CustomLink = styled(NavLink)`
	display: block;
	max-width: 240px;
	margin: 1em auto;
	button {
	    width: 100%;
	}
`;

export const NotFound = () => (
	<React.Fragment>
		<NotFoundWrapper>
			<span>404</span>
			<p>PAGE NOT FOUND</p>
		</NotFoundWrapper>
		<Title>Oops, looks like something went wrong</Title>
		<CustomLink to="/">
			<SecondaryButton>TAKE ME HOME</SecondaryButton>
		</CustomLink>
	</React.Fragment>
);

export default NotFound;
