import * as _ from "lodash";
import { computed, toJS, observable, action } from "mobx";
import CoreCollection from "../../core/collection";
import Api from "../../utils/Api";
import { LadderModel } from "../models/model.ladder";
import { Match } from "../models/model.match";
import { Picks, IPick } from "../models/model.picks";
import { Round } from "../models/model.round";
import { FinalsMatches } from "./collection.finals";
import Storage from "../../utils/Storage";
/**
 * @ignore
 */
export class Rounds extends CoreCollection<Round> {
	/**
	 * API method for request
	 */
	public get api() {
		return Api.JSON.rounds;
	}

	public get model() {
		return Round;
	}

	/**
	 * Returns matches without finals
	 */
	@computed
	public get matches() {
		const rounds = this.getItems();
		const rounds_obg = toJS(rounds);

		return _.flattenDeep(_.map(rounds_obg, "matches"));
	}

	/**
	 * Returns matches includes finals rounds
	 */
	@computed
	public get matchesWithFinal() {
		const rounds = this.getItems();
		return _.flattenDeep(_.map(rounds, (round: Round) => round.getMatches));
	}

	/**
	 * Returns completed matches includes finals rounds
	 */
	@computed
	public get completedAllMatches() {
		const rounds = this.completedRounds;
		const matches = _.map(rounds, (round: Round) => round.completedMatches);
		return _.flattenDeep(matches);
	}

	/**
	 * Returns scheduled matches includes finals rounds
	 */
	@computed
	public get scheduledAllMatches() {
		const rounds = this.scheduledRounds;
		const matches = _.map(rounds, (round: Round) => round.scheduledMatches);
		return _.flattenDeep(matches);
	}

	/**
	 * Returns playing matches includes finals rounds
	 */
	@computed
	public get playingAllMatches() {
		const rounds = this.activeRounds;
		const matches = _.map(rounds, (round: Round) => round.playingMatches);
		return _.flattenDeep(matches);
	}

	/**
	 * Returns current/actual round
	 */
	@computed
	public get getCurrentRound(): Round | undefined {
		const rounds = this.getItems();

		if (this.current_round && this.current_round.id) {
			return this.current_round;
		}

		// Todo: return current round of finals

		const actual_final_round = _.find(this.finalsRounds, (round: Round) => {
			return _.some(round.final_matches, (match: Match) => {
				return match.isSchedule || match.isPlaying;
			});
		});
		return (
			rounds.find((round: Round) => round.isActual) ||
			actual_final_round ||
			_.last(this.finalsRounds)
		);
	}

	/**
	 * Returns only final rounds
	 */
	@computed
	public get finalsRounds(): Round[] {
		const rounds = this.getItems();

		return rounds.filter((round: Round) => round.is_final);
	}

	/**
	 * Returns completed rounds
	 */
	@computed
	public get completedRounds() {
		const rounds = this.getItems();
		return rounds.filter((round: Round) => round.isComplete);
	}

	/**
	 * Returns scheduled and active rounds
	 */
	@computed
	public get scheduledAndActiveRounds() {
		const rounds = this.getItems();
		return rounds.filter((round: Round) => round.isActual);
	}

	/**
	 * Returns scheduled and active rounds
	 */
	@computed
	public get todaysRoundID() {
		const rounds = this.getItems();
		const filteredRounds = rounds.filter((round: Round) => round.isActual);
		return filteredRounds.length ? filteredRounds[0].id : 1;
	}

	/**
	 * Returns only scheduled rounds
	 */
	@computed
	public get scheduledRounds() {
		const rounds = this.getItems();
		return rounds.filter((round: Round) => round.isSchedule);
	}

	/**
	 * Returns array of active rounds
	 */
	@computed
	public get activeRounds() {
		const rounds = this.getItems();
		return rounds.filter((round: Round) => round.isActive);
	}

	/**
	 * Find last completed round id.
	 */
	@computed
	public get lastCompletedRoundID() {
		const rounds = this.getItems();
		return _.get(
			_.last(rounds.filter((round: Round) => round.isComplete)),
			"id",
			0
		);
	}

	/**
	 * Checks if user has missed some of rounds. Not fully filled/picked
	 * checks only scheduled rounds which less then current, including finals
	 */
	@computed
	public get getUnPickedRounds() {
		/**
		 * Commented for reference. Old code might affect other pages
		 */
		// const rounds = this.getItems();
		// const current_round = this.getCurrentRound;
		// const current_round_id = _.get(current_round, "id", 0);
		// const last_round_id = _.get(_.last(rounds), "id", 0);
		// const is_last_round_id = _.eq(last_round_id, current_round_id);

		// const last_completed_id = this.lastCompletedRoundID;

		// const isBetweenRounds = (id: number) =>
		// 	_.lt(id, current_round_id) && _.gt(id, last_completed_id);

		// const isFinal = (id: number) =>
		// 	_.eq(last_round_id, id) && is_last_round_id;

		// const isConforms = (id: number) => isBetweenRounds(id) || isFinal(id);
		// const filtered_rounds = _.filter(
		// 	rounds,
		// 	(round: Round) =>
		// 		isConforms(round.id) &&
		// 		!round.isFullFilled &&
		// 		!isFinal(round.id)
		// );
		// return _.map(filtered_rounds, (round: Round) => round.id);


		const current_round = this.getCurrentRound;
		const current_round_id = _.get(current_round, "id", 0);
		const picks = JSON.parse(Storage.get("picks") || "{}");

		const withPickRound = _.sortedUniq(_.map(picks, (pick) => pick.round_id));
		const getIdx = (iterate: number) => _.findIndex(withPickRound, (o) => o === iterate);

		const unPicked: number[] = [];
		for (let i = 1; i < current_round_id; i++) {
			const selected = this.getByID(i);
			const idx = getIdx(i);
			if (idx === -1 && selected!!.status !== "complete") {
				unPicked.push(i);
			}
		}
		return unPicked;

	}

	/**
	 * Returns final match
	 */
	@computed
	public get finalMatch() {
		/**
		 * Get last item recursively
		 */
		const LastItem = (items: any): any => {
			return items.lastItem ? LastItem(items.lastItem) : items;
		};

		return LastItem(this.matchesWithFinal);
	}

	public static final_names = {
		28: "Finals Week 1",
		29: "Finals Week 2",
		30: "Finals Week 3",
		31: "Grand final",
	};
	/* tslint:disable:no-magic-numbers */
	/**
	 * push additional rounds in order to build finals without Data from BE
	 */
	public static transform(result: any) {
		const template = _.head(result);

		const QF = {
			...template,
			bye_squads: [],
			id: 28,
			matches: [],
			is_final: true,
			matches_final: [],
			final_type: "QF",
			matrches: [1, 2, 3, 4],
		};

		const SF = {
			...template,
			bye_squads: [],
			id: 29,
			matches: [],
			is_final: true,
			matches_final: [],
			final_type: "SF",
			matrches: [5, 6],
		};

		const PF = {
			...template,
			bye_squads: [],
			id: 30,
			matches: [],
			is_final: true,
			matches_final: [],
			final_type: "PF",
			matrches: [7, 8],
		};

		const GF = {
			...template,
			bye_squads: [],
			id: 31,
			matches: [],
			is_final: true,
			is_grand_final: true,
			matches_final: [],
			final_type: "GF",
			matrches: [9],
		};

		return [...result, QF, SF, PF, GF];
	}

	/* tslint:enable:no-magic-numbers */

	@observable public current_round: Round | null = null;

	@observable public eliminated_squads: object = {};

	/**
	 * Returns rounds after round_id
	 */
	public getRoundsAfter(round_id: number) {
		const items = this.getItems();

		return _.filter(items, (round: Round) => round_id < round.id);
	}

	/**
	 * Change current round and fill match ids
	 * @see fillMatchIDS
	 */
	@action
	public changeCurrentRound(id: number) {
		const round = this.getByID(id);

		if (round) {
			round.fillMatchIDS();
			this.current_round = round;
		}
	}

	/**
	 * Find round's Index by round_id
	 */
	public getIndexByID(id: number) {
		const rounds = this.getItems();
		return rounds.findIndex((round: Round) => _.eq(round.id, id));
	}

	/**
	 * Find round by index in array (getIndexByID)
	 */
	public getByIndex(index: number) {
		const rounds = this.getItems();
		return rounds[index];
	}

	/**
	 * Find round by ID
	 */
	public getByID(id: number) {
		const rounds = this.getItems();
		return rounds.find((round: Round) => _.eq(round.id, id));
	}
	/**
	 * Returns completed matches by squad_ud
	 */
	public getCompletedMatchesBySquadID(squad_id: number) {
		const matches = this.completedAllMatches;

		return _.filter(
			matches,
			(match: Match) =>
				_.eq(match.away_squad_id, squad_id) ||
				_.eq(match.home_squad_id, squad_id)
		);
	}

	/**
	 * Fill first round based on ladder
	 */
	public fillFirstFinal(data: any) {
		const { match_options, items, position } = data;

		const options = {} as any;
		if (match_options.id) {
			options.home_squad_id = _.get(
				items,
				`${position[0]}.id`,
				undefined
			);
			options.away_squad_id = _.get(
				items,
				`${position[1]}.id`,
				undefined
			);
		}
		return {
			...match_options,
			...options,
		};
	}

	/**
	 * Fill second round based on ladder
	 */
	public fillSecondFinal(data: any) {
		const { match_options, prev_round_matches, picks, index } = data;

		const options = {} as any;
		const TWO = 2;
		const prev_round_matches_by_QF = _.chunk(prev_round_matches, TWO);

		/**
		 * In order to build second match, I don't know how to determine this part more clear
		 *
		 * if i === 1 then we have to build second match of Final week 2
		 */
		const prev_sf = index
			? prev_round_matches_by_QF[index].reverse()
			: prev_round_matches_by_QF[index];

		const first_match_qf1 = prev_sf[0] as Match;
		const pick_qf1 = picks.getByID(_.get(first_match_qf1, "id", 0));

		const buildFirstMatchOfFinalW = (match: Match, pick: IPick) => {
			if (!pick && !match.isComplete) {
				return;
			}
			const away_squad_id = _.get(match, "away_squad_id");
			const home_squad_id = _.get(match, "home_squad_id");
			const is_won =
				_.eq(match.winnerID, away_squad_id) ||
				_.eq(_.get(pick, "squad_id"), away_squad_id);
			if (is_won) {
				options.home_squad_id = home_squad_id;
				options.in_next_round = away_squad_id;
			} else {
				options.home_squad_id = away_squad_id;
				options.in_next_round = home_squad_id;
			}
		};

		const buildSecondMatchOfFinalW = (match: Match, pick: IPick) => {
			if (!pick && !match.isComplete) {
				return;
			}
			const away_squad_id = _.get(match, "away_squad_id");
			const home_squad_id = _.get(match, "home_squad_id");
			const is_won =
				_.eq(match.winnerID, home_squad_id) ||
				_.eq(_.get(pick, "squad_id"), home_squad_id);
			if (is_won) {
				options.away_squad_id = home_squad_id;
				this.eliminated_squads[match.id] = away_squad_id;
			} else {
				options.away_squad_id = away_squad_id;
				this.eliminated_squads[match.id] = home_squad_id;
			}
		};

		buildFirstMatchOfFinalW(first_match_qf1, pick_qf1);

		const second_match_qf1 = prev_sf[1] as Match;
		const pick_second_qf1 = picks.getByID(_.get(second_match_qf1, "id", 0));

		buildSecondMatchOfFinalW(second_match_qf1, pick_second_qf1);

		return {
			...match_options,
			...options,
		};
	}

	/**
	 *  Week three
	 */
	public fillSecondFinalPF(data: any) {
		const { index, prev_round_matches, match_options, picks } = data;

		const options = {} as any;

		const prefix = !index ? 1 : 0;

		const first_SF = prev_round_matches[index];
		const first_SF_Opponent = prev_round_matches[prefix] as Match;

		if (first_SF) {
			const pick = picks.getByID(first_SF_Opponent.id);
			options.home_squad_id = first_SF.in_next_round;

			if (!pick && !first_SF_Opponent.isComplete) {
				return options;
			}

			const isWon = (squad_id: number) =>
				_.eq(first_SF_Opponent.winnerID, squad_id) ||
				_.eq(_.get(pick, "squad_id"), squad_id);
			if (isWon(first_SF_Opponent.home_squad_id)) {
				options.away_squad_id = first_SF_Opponent.home_squad_id;
				this.eliminated_squads[first_SF_Opponent.id] =
					first_SF_Opponent.away_squad_id;
			} else {
				if (isWon(first_SF_Opponent.away_squad_id)) {
					options.away_squad_id = first_SF_Opponent.away_squad_id;
					this.eliminated_squads[first_SF_Opponent.id] =
						first_SF_Opponent.home_squad_id;
				}
			}
		}

		return {
			...match_options,
			...options,
		};
	}

	/**
	 *  Week four
	 */
	public filFinalGF(data: any) {
		const { prev_round_matches, match_options, picks } = data;

		let options = {} as any;

		const first_PF = _.head(prev_round_matches) as Match;
		const first_PF2 = _.last(prev_round_matches) as Match;

		const fillPF = (
			pick: IPick,
			match: Match,
			opts: any,
			squad_side: "home_squad_id" | "away_squad_id"
		) => {
			if (!pick && !match.isComplete) {
				return opts;
			}

			const is_won =
				_.eq(match.winnerID, match.home_squad_id) ||
				_.eq(_.get(pick, "squad_id"), match.home_squad_id);

			if (is_won) {
				opts[squad_side] = match.home_squad_id;
				this.eliminated_squads[match.id] = match.away_squad_id;
			} else {
				opts[squad_side] = match.away_squad_id;
				this.eliminated_squads[match.id] = match.home_squad_id;
			}

			return opts;
		};
		if (first_PF) {
			const pick = picks.getByID(first_PF.id);
			options = fillPF(pick, first_PF, options, "home_squad_id");
		}

		if (first_PF2) {
			const pick = picks.getByID(first_PF2.id);

			options = fillPF(pick, first_PF2, options, "away_squad_id");
		}

		/**
		 * Set eliminated for Grand final match
		 */
		if (match_options.id) {
			const final_pick = picks.getByID(match_options.id);

			if (final_pick || match_options.isComplete) {
				const is_won =
					_.eq(match_options.winnerID, options.home_squad_id) ||
					_.eq(_.get(final_pick, "squad_id"), options.home_squad_id);
				if (is_won) {
					this.eliminated_squads[match_options.id] =
						options.away_squad_id;
				} else {
					this.eliminated_squads[match_options.id] =
						options.home_squad_id;
				}
			}
		}

		return {
			...match_options,
			...options,
		};
	}

	/**
	 * Init finals by NRL finals system
	 * Week one
	 * 1st Qualifying Final: 1st ranked team hosts 4th ranked team
	 * 2nd Qualifying Final: 2nd ranked team hosts 3rd ranked team
	 * 1st Elimination Final: 5th ranked team hosts 8th ranked team
	 * 2nd Elimination Final: 6th ranked team hosts 7th ranked team
	 * * Week two
	 * 1st Semi-final: Loser of 1st QF hosts winner of 1st EF
	 * 2nd Semi-final: Loser of 2nd QF hosts winner of 2nd EF
	 * * Week three
	 * 1st Preliminary Final:[3] Winner of 1st QF hosts winner of 2nd SF
	 * 2nd Preliminary Final: Winner of 2nd QF hosts winner of 1st SF
	 * * Week four
	 * Grand Final: Winner of 1st PF meets Winner of 2nd PF
	 */
	public initFinals(
		ladder_items: LadderModel[],
		picks: Picks,
		_matches_fin: FinalsMatches
	) {
		const TWO = 2;
		const final_rounds = _.filter(
			this.getItems(),
			(round: Round) => round.isFinal
		);
		const items = _.take(ladder_items, ladder_items.length / TWO);
		if (!items.length) {
			return;
		}
		let prev_matches = {};
		final_rounds.forEach((round: Round) => {
			const matches = [];
			prev_matches[round.id] = [];
			const final_data = round.finalDefinisions;

			for (let i = 0; i < final_data.match_counts; i++) {
				const id = parseInt(`111${round.id}${i + 1}0`, 10);
				const position = final_data.match_positions[i];

				const prev_round_matches = prev_matches[round.id - 1];
				const match_options = {
					id,
					round: round.id,
					match: i + 1,
					status: "scheduled",
					in_next_round: 0 as number,
					home_squad_id: undefined,
					away_squad_id: undefined,
					is_final: true,
				} as Partial<Match>;

				const fillRoundMethod = _.bind(
					{
						QF: this.fillFirstFinal,
						SF: this.fillSecondFinal,
						PF: this.fillSecondFinalPF,
						GF: this.filFinalGF,
					}[round.final_type],
					this
				);

				const match_options_result = {
					...match_options,
					...fillRoundMethod({
						position,
						items,
						match_options,
						round,
						prev_round_matches,
						picks,
						index: i,
					}),
				};

				const { home_squad_id, away_squad_id } = match_options_result;
				const final_match_data =
					_matches_fin.findMatch(
						[home_squad_id, away_squad_id],
						final_data.match_range
					) || {};
				const match = new Match({
					...match_options_result,
					...final_match_data,
				});

				matches.push(match);

				prev_matches = {
					...prev_matches,
					[round.id]: [...prev_matches[round.id], match],
				};
			}

			round.final_matches = [...matches];

			round.fillMatchIDS();
		});
	}
}
