import * as _ from "lodash";
import { observer } from "mobx-react";
import React, { Component } from "react";
import styled, { css } from "styled-components";
import media from "../../../../assets/css/media";
import blank_logo from "../../../../assets/img/icons/blank-logo.svg";
import favorite_squad_icon from "../../../../assets/img/icons/favor_green.svg";

import {
	Image,
	PickMarker
} from "../../../../components";
import {
	MobxContext,
	IRootStore
} from "../../../../modules/stores";
import { Match } from "../../../../modules/stores/models/model.match";
import { Squad as TSquad } from "../../../../modules/stores/models/model.squad";
import { parseOrdinal } from "../../../../modules/utils/dom_helpers";


interface ISquadWrapperStyled {
	is_picked?: boolean;
	is_draw?: boolean;
}

const Points = styled.div<{ is_won: boolean }>`
	color: #222;
	flex: auto;
	display: flex;
	justify-content: center;
	font-size: 2.25em;
	font-weight: ${({ is_won }) => is_won ? "bold" : "normal"};

	@media (max-width: ${media.desktop}) {
		font-size: 1.5em;
	}

`;

const SquadWrapper = styled.div<ISquadWrapperStyled>`
	display: flex;
	flex: 2;
	height: 115px;
	align-items: center;
	padding: 0 1em;
	transition: all 0.3s linear;
	${({ is_picked }: ISquadWrapperStyled) => is_picked && css`
		transition: all 0.3s linear;
		//background: linear-gradient(to right, rgba(0,207,93,0.1), #fff);
		//&:last-child{
		//	background: linear-gradient(to left, rgba(0,207,93,0.1), #fff);
		//}
	`}

	${({ is_draw }: ISquadWrapperStyled) => is_draw && css`
		transition: all 0.3s linear;
		//background: linear-gradient(to right, rgba(220,220,220,0.1), #fff);
		//&:last-child{
		//	background: linear-gradient(to left, rgba(220,220,220,0.1), #fff);
		//}
	`}

	&:last-child{
		flex-direction: row-reverse;
		padding-left: 0;
	}

	&:first-child {
		padding-right: 0;
	}

	@media (max-width: ${media.desktop}) {
		height: 80px;
		padding: 0;
	}
`;

const SquadContent = styled.div`
	padding: 0 1em;
	text-align: center;
	@media (max-width: ${media.desktop}) {
		padding: 0 0.5em;
	}

`;

const SquadName = styled.p`
	color: #222;
	font-size: 13px;
	font-weight: 500;
	margin-bottom: 0.25em;
`;

const Position = styled.p`
	font-size: 13px;
	color: #595959;
	display: flex;
	align-items: center;
	img {
		margin-left: 0.5em;
	}
`;

const ImageWrapper = styled.div`
	max-width: 60px;
	max-height: 60px;
	height: 60px;
	margin: 0 auto 0.25em;
	display: flex;
	align-items: center;
	cursor: pointer;
	img {
		height: auto;
	}

	.blank {
		max-height: 50px;
	}

	@media (max-width: ${media.desktop}) {
		max-height: 40px;
		max-width: 40px;
	}
`;

interface IProps {
	match: Match;
	squad_id: number;
}

@observer
class Squad extends Component<IProps> {

	public static contextType = MobxContext;
	public context!: React.ContextType<typeof MobxContext>;

	public get injected() {
		return this.context as IRootStore;
	}

	constructor(props: IProps) {
		super(props);
		_.bindAll(this, [
			"makePick",
		]);
	}

	public getFavoriteIcon(squad: TSquad) {
		const { ladder } = this.injected;
		const ladder_squad = ladder.getLadderById(squad.id);
		if ( ladder_squad && ladder_squad.is_favorite ) {
			return <img src={favorite_squad_icon} alt=""/>;
		}

		return null;

	}

	public render() {

		const { squads, picks, ladder } = this.injected;

		const { match, squad_id } = this.props;
		if ( !squads || !match ) {
			return null;
		}
		const squad = squads.getSquadById(squad_id);
		const onClick = _.partial(this.makePick, squad_id);
		const pick = _.get(picks, `picks.${match.id}`, {});
		const is_picked = _.eq(pick.squad_id, squad_id);

		const is_complete = match.isComplete;
		const points_stat = this.squad_points[ squad_id ];
		const ladder_squad = ladder.getLadderById(squad_id);

		return squad && ladder_squad ?
			is_complete ? (
				<SquadWrapper>
					<SquadContent>
						<ImageWrapper>
							<Image squad_id={squad_id}/>
						</ImageWrapper>
						<SquadName>{squad.name}</SquadName>
					</SquadContent>

					<Points is_won={points_stat.is_won}>
						{points_stat.score}
					</Points>

					{this.getFavoriteIcon(squad)}
				</SquadWrapper>
			) : (
				<SquadWrapper is_picked={is_picked} is_draw={pick.is_draw}>
					<PickMarker
						picked={is_picked}
						onClick={onClick}
						is_draw={pick.is_draw}
						is_show_empty={true}
					/>
					<SquadContent>
						<ImageWrapper>
							<Image squad_id={squad_id} onClick={onClick}/>
						</ImageWrapper>
						<SquadName>{squad.name}</SquadName>
						<Position>
							{parseOrdinal(ladder_squad.position)}
							<img src={ladder_squad.getIcons} alt=""/>
						</Position>
					</SquadContent>

					{this.getFavoriteIcon(squad)}

				</SquadWrapper>
			) : (
				<SquadWrapper>
					<SquadContent>
						<ImageWrapper>
							<img src={blank_logo} className="blank" alt="Empty squad"/>
						</ImageWrapper>
						<SquadName>TBC</SquadName>
					</SquadContent>
				</SquadWrapper>
			);
	}

	private get squad_points() {
		const { match } = this.props;

		return {
			[ match.away_squad_id ]: {
				score : match.away_score,
				is_won: match.away_score > match.home_score,
			},
			[ match.home_squad_id ]: {
				score : match.home_score,
				is_won: match.home_score > match.away_score,
			},
		};
	}

	private makePick(squad_id: number) {
		const { picks, rounds, user, ladder } = this.injected;
		const round = rounds.getCurrentRound;
		const { match } = this.props;
		const pick = picks.getByID(match.id) || {};
		if ( picks && round ) {
			if ( pick && pick.squad_id === squad_id ) {
				picks.deletePicks([ pick.id ]);
				round.removePickMatchIDs(pick.id);

				// if(round.isFinal) {
				// 	const rounds_after = rounds.getRoundsAfter(round.id);
				// 	const matches = _.flatten(_.map(rounds_after, (round: Round) => toJS(round.final_matches)));
				// 	const match_ids = _.map(matches, 'id');
				//
				// 	picks.deletePicks(match_ids);
				// 	_.invoke(rounds_after, 'fillMatchIDS');
				// }

			} else {
				picks.savePicks({
					id        : match.id,
					squad_id,
					round_id  : match.round,
					draw_index: pick.draw_index || Match.default_draw_index,
					is_draw   : false,
					is_final  : round.is_final,
					is_grand_final: round.is_grand_final,
					form_data:{
						nrl_account_id: user.nrl_account_id,
						nrl_club: ladder.favorite_team ? ladder.favorite_team.id:0,
						round: rounds.todaysRoundID,
						ladder: ladder.ladderByPosition,
						premier_winner: squad_id
					}
				});

				round.setPickMatchIDs(match.id);
			}

		}
	}
}

export default Squad;
