import styled, { css } from "styled-components";

interface IModelWrapper {
	readonly is_open?: boolean;
}

export const ModalWrapper = styled.div<IModelWrapper>`
	position: fixed;
	display: flex;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	max-width: 0;
	max-height: 0;
	z-index: 4;
	background: ${(props) => props.theme.color};
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition: all 0.3s linear;
	transform: translate(-50%, 50%);
	transition-property: max-height, opacity, max-width;
	overflow: hidden;
	transform-origin: center;
	${({ is_open }) => is_open && css`
		opacity: 1;
	    max-height: 100%;
	    max-width: 100%;
	  	top: -50%;
	`}
`;
export default ModalWrapper;
