import { IModelUser } from '../../types';
import { Storage } from "../Storage";


interface ISaveUser {
	readonly user: IModelUser
	readonly session_id?: string
}

export const saveUserToStorage = ({ user, session_id }: ISaveUser): void => {
	Storage.set('user', user);
	Storage.set('is_authorized', true);

	if ( session_id !== undefined ) {
		Storage.set('sid', session_id);
	}
};

export const clearUserFromStorage = (): void => {
	Storage.remove('user');
	Storage.remove('is_authorized');
	Storage.remove('sid');
};

export const getUserObjectFromStorage = (): IModelUser => {
	try {
		return JSON.parse(Storage.get('user') || '{}');
	} catch ( e ) {
		clearUserFromStorage();
		return getUserObjectFromStorage();
	}
};

/* tslint:disable */
const onlyAlphabets = (charCode: number) => {
	return ( charCode > 64 && charCode < 91 ) || ( charCode > 96 && charCode < 123 ) || charCode === 32;
};


export const generatePassword = (length: number) => {
	let password = '';
	let character = '';

	while ( length > password.length ) {
		character = String.fromCharCode(Math.floor(Math.random() * 94) + 33);

		if ( onlyAlphabets(character.charCodeAt(0)) && password.indexOf(
			character,
			Math.floor(password.length / 94) * 94) < 0
		) {
			password += character;

		}
	}
	return password;
};
/* tslint:enable */

export const getUserKey = () => {
	const USER_KEY_LENGTH = 8;
	const { user_key: key } = getUserObjectFromStorage();

	if ( key ) {
		return key;
	} else {
		const user_key = generatePassword(USER_KEY_LENGTH);
		saveUserToStorage({
			user: {
				user_key
			}
		});

		return user_key;
	}
};