import styled from "styled-components";

const image_url = process.env.REACT_APP_IMAGE_SQUADS || "";

interface IOwn {
	readonly squad_id: number;
	readonly background?: string;
	readonly height?: number;
}

export const ImageAsBG = styled.div<IOwn>`
	width: 100%;
	height: 100%;
	background-image: url("${({ squad_id }) => squad_id ? image_url.replace("--squad--", squad_id.toString()): 'none'}");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	padding: ${({ height }) => height ? `${height / Number('2')}px` : "20px"} 1em;
`;
