import { when, reaction, autorun } from "mobx";
import React from "react";
import CoreCollection from "../core/collection";
import { Rounds, Squads, Ladder, FinalsMatches } from "./collections";
import { Match } from "./models/model.match";
import { Picks } from "./models/model.picks";
import { UserModel } from "./models/model.user";

export interface IRootStore {
	readonly rounds: Rounds;
	readonly squads: Squads;
	readonly ladder: Ladder;
	readonly picks: Picks;
	readonly user: UserModel;
}

/**
 * @ignore
 */
export class RootStore {
	public readonly rounds: Rounds;
	public readonly squads: Squads;
	public readonly ladder: Ladder;
	public readonly picks: Picks;
	public readonly user: UserModel;
	public readonly final_matches: FinalsMatches;

	constructor() {
		this.ladder = new Ladder();
		this.rounds = new Rounds();
		this.squads = new Squads();
		this.picks = new Picks();
		this.user = new UserModel();
		this.final_matches = new FinalsMatches();

		this.initGame();
	}

	/**
	 * @ignore
	 */
	public initGame() {
		/**
		 * Waiting for data and starts initialization
		 * this.user.is_logged_in used to be in here
		 * when login was required
		 */
		when(
			() => !this.rounds.isEmpty && !this.ladder.isEmpty,
			() => this.initLadder()
		);

		/**
		 *  Waiting for data with picks
		 */
		when(
			() =>
				!this.rounds.isEmpty &&
				!this.ladder.isEmpty &&
				!this.picks.isEmpty,
			() => this.fillMatchIDSForCurrentRound()
		);
		/**
		 *  Waiting for data
		 */
		when(
			() => !this.rounds.isEmpty && !this.ladder.isEmpty,
			() => this.addLadderToRound()
		);

		this.watchPicks();
		this.listenOnSwitchToCurrentLadder();
	}

	/**
	 * Inits current ladder according to picks.
	 */
	public initLadder() {
		this.ladder
			// .checkOdds(this.user.age)
			.calculateLast5ByPicks(this.rounds, this.picks)
			.preFillFavorite()
			.calculateByPicks(this.rounds.matches as Match[], this.picks)
			.setChangesPositions()
			.calculateBye(this.rounds, this.picks);

		return this;
	}

	/**
	 * Some runners for picks model.
	 */
	public watchPicks() {
		/**
		 * Init finals once we get data
		 */
		when(
			() => this.final_matches.state === CoreCollection.statuses.FINISHED,
			() => {
				autorun(() => {
					const picks = this.picks;
					const items = this.ladder.sortedItems;
					if (!this.ladder.is_switch_to_current) {
						this.rounds.initFinals(
							items,
							picks,
							this.final_matches
						);
					}
				});
			}
		);

		/**
		 * Looking at changes of picks and recalculate ladder,
		 * re-init finals according to picks
		 */
		reaction(
			() => this.picks.picks,
			() => {
				const round = this.rounds.getCurrentRound;
				if (round && !round.is_final) {
					this.ladder
						.reset(this.ladder.actual_ladder)
						.preFillFavorite()
						.calculateLast5ByPicks(this.rounds, this.picks)
						.calculateByPicks(
							this.rounds.matches as Match[],
							this.picks
						)
						.setChangesPositions()
						.calculateBye(this.rounds, this.picks);
				}

				const picks = this.picks;
				const items = this.ladder.sortedItems;

				this.rounds.initFinals(items, picks, this.final_matches);
			}
		);

		this.final_matches.getItems();
	}

	/**
	 * In order to calculate matches with picks
	 */
	public fillMatchIDSForCurrentRound() {
		const round = this.rounds.getCurrentRound;
		if (round) {
			round.setCurrentLadder(this.ladder.actual_ladder);
			/**
			 * In order to determine how round is going. Filled, Empty
			 */
			round.fillMatchIDS();
		}
	}

	/**
	 * Set ladder to make autofill select winner
	 */
	public addLadderToRound() {
		const round = this.rounds.getCurrentRound;
		if (round) {
			round.setCurrentLadder(this.ladder.actual_ladder);
		}
	}

	/**
	 * Change ways to calculate ladder.
	 * is_switch_to_current - shows if currently user wants to see current ladder
	 */
	public listenOnSwitchToCurrentLadder() {
		reaction(
			() => this.ladder.is_switch_to_current,
			() => {
				this.ladder
					.reset(this.ladder.actual_ladder)
					.calculateBye(this.rounds, this.picks)
					.preFillFavorite();

				if (this.ladder.is_switch_to_current) {
					this.ladder.calculateLast5ByPicks(this.rounds);
				} else {
					this.ladder.calculateLast5ByPicks(this.rounds, this.picks);
					this.ladder.calculateByPicks(
						this.rounds.matches as Match[],
						this.picks
					);
				}

				this.ladder.setChangesPositions();
			}
		);
	}

	/**
	 * Returns object with store data
	 */
	public toJS() {
		return {
			rounds: this.rounds,
			ladder: this.ladder,
			squads: this.squads,
			user: this.user,
			picks: this.picks,
		};
	}
}

export default {
	...new RootStore().toJS(),
};

export const MobxContext = React.createContext<IRootStore | null>(null);
