import * as _ from 'lodash';
import { observer } from "mobx-react";
import React, { Component } from 'react';
import styled, { css } from "styled-components";
import media from "../../assets/css/media";
import theme from "../../assets/css/theme";
import arrow from '../../assets/img/icons/arrow-right.svg';
import favor_gray from '../../assets/img/icons/favor_gray.svg';
import favor_green from '../../assets/img/icons/favor_green.svg';
import {
	Title,
	SwitchToggle,
	ScrollToOnClick
} from '../../components';
import {
	MobxContext,
	IRootStore
} from "../../modules/stores";
import { LadderModel } from "../../modules/stores/models/model.ladder";
import sportsbet from '../../assets/img/sportsbet.svg';


const SquadItemWrapper = styled.li<{ is_white: boolean, is_favorite: boolean }>`
	list-style: none;
	width: 100%;
	padding: 0.5em 0;
	border-bottom: 1px solid #E8E8E8;
	background: ${({ is_white }) => is_white ? '#fff' : '#F2F2F2'};
	border-left: 3px solid transparent;
	transition: all 0.3s ease;
	
	${({ is_favorite }) => is_favorite && css`
		border-left: 3px solid #00CF5D;
		background: #F5FCF8;
	`}
	
	&:first-child {
		border-top: 1px solid #E8E8E8;;
	}
`;

const SquadInfo = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	height: 40px;
	align-items: center;
	
	@media (max-width: ${media.mobile}){
		font-size: 12px;
	}
`;

const Item = styled.div`
	flex: 1;
	text-align: center;
	
	@media (min-width: ${media.desktop}){
		font-size: 12px;
	}
`;

const TeamName = styled(Item)`
	flex: 3;
	letter-spacing: 0.5px;
	text-align: left;
	display: flex;
	align-items: center;
	border-right: 1px solid #E8E8E8;
	font-size: 13px;
	img {
		height: 45px;
		width: auto;
		margin-right: 1em;
	}
	
	@media (max-width: ${media.desktop}) {
		flex: 1;
		max-width: 90px;
		& > span {
			display: none;
		}
		
		img	{
			margin: 0;
		}
	}
	
	@media (max-width: ${media.mobile}) {
		font-size: 12px;
	}
`;


const LadderTagLine = styled.div`
	display: flex;
	color: #767676;
	font-size: 14px;
	letter-spacing: 1px;
	font-family: ${theme.base_font};
	height: 52px;
	align-items: center;
	
	${TeamName} {
		border-right: none;
	}
	
	${Item} {
		span + span {
		display: none;
		}
		@media (max-width: ${media.desktop}) {
			span {
				&:first-child {
					display: none;
				}
				&:last-child {
					display: inline;
				}
			}
			span + span {
				display: inline;
			}
			
		}
	}
`;


const PositionItem = styled(Item)`
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	img {
		margin-left: 0.25em;
	}
`;

const FavoriteItem = styled(Item)`
	cursor: pointer;
	max-width: 60px;
	border-left: 1px solid #E8E8E8;
`;

const ItemDesktop = styled(Item)`
	@media (max-width: ${media.desktop}) {
		display: none;
	}
`;

const ItemBasis = styled(Item)`
	flex-basis: 2%;
`;

const ItemBasisDesktop = styled(ItemDesktop)`
	flex-basis: 2%;
`;

const ItemOddsHead = styled(Item)`
	background-color: #024995;
	color: #FFFFFF;
	font-weight: bold;
	font-size: 12px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-flow: column;
	padding: 0 8px;
	font-style: italic;
	
	span{
		font-size: 8px;
		font-weight: normal;
		text-transform: uppercase;
	}
`;

const ItemOdds = styled.a`
	flex: 1;
	text-align: center;
	background-color: #E5F5FF;
	color: #024A95;
    height: 140%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    text-decoration: none;
    
    @media (min-width: ${media.desktop}){
		font-size: 12px;
	}
`;

const ItemBold = styled(Item)`
	font-weight: 700;
`;

const FavoriteMobileTeam = styled.div<{ is_fixed: boolean }>`
	background: #fff;
	display: none;
	box-shadow: 0 0 14px 0 rgba(0,0,0,0.1);
	${({ is_fixed }) => is_fixed && css`
		position:fixed;
		width:100%;
		left:0;
		bottom:0;
		z-index:1;
		`
	};

	@media (max-width: ${media.desktop}) {
		display: none;
		${({ is_fixed }) => is_fixed &&
		`display:block;`
	};
		&.fixed {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
		}
	}
`;

const FavoriteMobileTeamTitle = styled.div`
		font-size: 14px;
		color: #595959;
		padding: 0.75em;
		text-align: center;
		display: flex;
    	align-items: center;
    	justify-content: center;
`;

const DesktopSwitch = styled.div`
	display: flex;
	align-items: center;
	@media (max-width: ${media.desktop}) {
		display: none;
	}
`;
const MobileSwitch = styled.div`
	align-items: center;
	display: none;
	@media (max-width: ${media.desktop}) {
		display: flex;
	
	}
`;

const SportsBetLogo = styled.img`
	width: 68px;
	height: 15px;
	font-style: italic;
	font-weight: bold;
`;

interface IState {
	current_ladder: boolean
}

const Arrow = styled.div`
	position: relative;
	max-width: 50px;
	width: 16px;
	height: 16px;
	cursor: pointer;
	&:before{
		transform: rotate(90deg);
		content: '';
		display: block;		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url("${arrow}") center no-repeat;
		background-size: 8px;
		transition: all 0.3s ease;
	}
`;

// const IS_ODDS_COLUMN_DISABLED = false;

@observer
class Ladder extends Component<any, IState> {

	public get injected() {
		return this.context as IRootStore;
	}

	public get rows() {
		const { ladder } = this.injected;

		const teams = ladder.sortedItems;
		return teams.map((squad: LadderModel, index: number) => this.getRow(squad, teams, index));
	}

	public static contextType = MobxContext;
	public state = {
		current_ladder: false
	};
	public context!: React.ContextType<typeof MobxContext>;

	private readonly favMobileRef: React.RefObject<any>;

	constructor(props: any) {
		super(props);

		_.bindAll(this, [
			'setFavorite',
			'changeLadderState',
			'changeLadderOddsState',
			'onScroll'
		]);

		this.favMobileRef = React.createRef();
	}

	public componentDidMount(): void {
		const { ladder } = this.injected;
		ladder.changeFixedPosition(true);
		window.addEventListener('scroll', this.onScroll);
	}

	public componentWillUnmount() {
		window.removeEventListener('scroll', this.onScroll);
	}

	// tslint:disable-next-line:cyclomatic-complexity
	public getRow(squad: LadderModel, teams: LadderModel[] = [], index: number = 0) {
		const { ladder } = this.injected;
		const image_url = process.env.REACT_APP_IMAGE_SQUADS || '';
		// const is_odds_column_disabled = ladder.is_odds_disabled;

		return (
			<SquadItemWrapper
				key={index || 0}
				is_white={index < (teams.slice(0, 8).length)}
				is_favorite={squad.is_favorite}
			>
				<SquadInfo>
					<PositionItem>
						{index + 1}

						<img src={squad.getIcons} alt="Position changes" />
					</PositionItem>
					<TeamName>
						<img
							src={image_url.replace('--squad--', squad.id.toString())}
							alt={squad.name + ' squad'}
						/>
						<span>{squad.name}</span>
					</TeamName>
					<Item>{squad.games}</Item>
					<ItemBold>{squad.points}</ItemBold>
					<ItemBasis>{squad.WDL}</ItemBasis>
					<ItemDesktop>{squad.bye_count}</ItemDesktop>
					<ItemDesktop>{squad.margin}</ItemDesktop>
					<ItemBasisDesktop>{squad.formWDL}</ItemBasisDesktop>
					{ladder.is_switch_to_odds && (
						<>
							{/* eslint-disable-next-line no-template-curly-in-string */}
							< ItemOdds target="_blank" href="https://ad.doubleclick.net/ddm/trackclk/N7629.128652.NRL/B25296542.295237263;dc_trk_aid=488815583;dc_trk_cid=142873052;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=">
								${squad.odds_top8 || '--'}
							</ItemOdds>
						</>
					)}
					<FavoriteItem
						onClick={squad.is_favorite ? function () {
							return
						} : _.partial(this.setFavorite, squad.id)}>
						{squad.is_favorite ? (
							<img src={favor_green}
								alt="Selected Favorite Team" />
						) : (
							<img src={favor_gray} alt="Favorite Team" />
						)}

					</FavoriteItem>
				</SquadInfo>
			</SquadItemWrapper >
		);
	}

	public render() {
		const { ladder, user } = this.injected;
		const display_odds = _.gte(user.age, 18);

		const favorite_team = ladder.favorite_team;
		const { refOnClick, refElem } = this.props;
		// const is_odds_column_disabled = ladder.is_odds_disabled || IS_ODDS_COLUMN_DISABLED;
		return (
			<div ref={this.favMobileRef}>
				{favorite_team && (
					<FavoriteMobileTeam is_fixed={ladder.is_favorites_fixed}>
						<ScrollToOnClick toRef={refOnClick}>
							<FavoriteMobileTeamTitle>
								Ladder Prediction <Arrow />
							</FavoriteMobileTeamTitle>
						</ScrollToOnClick>
						{this.getRow(favorite_team)}
					</FavoriteMobileTeam>
				)}

				<Title hide_mobile={false} ref={refElem}>
					<p>Your Ladder Predictions</p>

					<DesktopSwitch>
						<SwitchToggle
							id="ladder"
							name="ladder"
							label="Actual Ladder"
							value={ladder.is_switch_to_current}
							onChange={this.changeLadderState}
						/>
						{display_odds && <SwitchToggle
							id="ladder_odds"
							name="ladder_odds"
							label="Odds"
							value={ladder.is_switch_to_odds}
							onChange={this.changeLadderOddsState}
							className="ladder_odds"
						/>}
					</DesktopSwitch>
					<MobileSwitch>
						<SwitchToggle
							id="ladder_mobile"
							name="ladder_mobile"
							label="Current"
							value={ladder.is_switch_to_current}
							onChange={this.changeLadderState}
						/>
						{display_odds && <SwitchToggle
							id="ladder_odds_mobile"
							name="ladder_odds_mobile"
							label="Odds"
							value={ladder.is_switch_to_odds}
							onChange={this.changeLadderOddsState}
							className="ladder_odds_mobile"
						/>}
					</MobileSwitch>
				</Title>

				<LadderTagLine>
					<Item>
						<span>Pos</span>
					</Item>
					<TeamName>
						<span>Team</span>
					</TeamName>
					<Item>
						<span>Pld</span>
						<span>Pls.</span>
					</Item>
					<Item>
						<span>Points</span>
						<span>Pts.</span>
					</Item>
					<ItemBasis>
						<span>W-D-L</span>
					</ItemBasis>
					<ItemDesktop>
						<span>Byes</span>

					</ItemDesktop>
					<ItemDesktop>
						<span>Diff</span>

					</ItemDesktop>
					<ItemBasisDesktop>
						<span>Form</span>

					</ItemBasisDesktop>
					{ladder.is_switch_to_odds && <ItemOddsHead>
						<SportsBetLogo src={sportsbet} />
						<span>Top 8 Finish</span>
					</ItemOddsHead>}
					<FavoriteItem>
						<span>Fav</span>
					</FavoriteItem>
				</LadderTagLine>
				{this.rows}
			</div>
		);
	}

	private onScroll() {
		const { ladder } = this.injected;
		const height_dom_window = window.scrollY + window.innerHeight;

		ladder.changeFixedPosition(height_dom_window - this.favMobileRef.current.offsetTop < 110);
	}

	private setFavorite(squad_id: number) {
		const { ladder } = this.injected;
		ladder.setFavorite(squad_id, true);
	}

	private changeLadderState(e: any) {
		const state = _.get(e, 'currentTarget.checked');
		const { ladder } = this.injected;

		ladder.changeLadderState(state);
	}

	private changeLadderOddsState(e: React.SyntheticEvent<HTMLInputElement>) {
		const state = _.get(e, 'currentTarget.checked');
		const { ladder } = this.injected;
		ladder.changeLadderOddsState(state);
	}
}

export default Ladder;

