import * as _ from "lodash";
import * as React from "react";
import styled, { css } from "styled-components";
import { media } from "../../assets/css/media";
import arrow from "../../assets/img/icons/arrow-right.svg";

const AccordionContent = styled.div`
	max-height: 0;
	overflow: hidden;
    transition: max-height 0.3s ease-out;
    box-sizing: content-box;
    /* padding: 0 1em; */
	${({ is_open }: IAccordionState) => is_open && css`
		transition: max-height 0.5s ease-in;
		max-height: 100vh;
	`}
`;
const Wrapper = styled.div`
	height: 100%;
`;

const Arrow = styled.div`
	position: relative;
	max-width: 50px;


	width: 16px;
	height: 16px;
	&:before{
		transform: rotate(90deg);
		content: '';
		display: block;		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url("${arrow}") center no-repeat;
		background-size: 8px;
		transition: all 0.3s ease;
	}

	${({ is_open }: IAccordionState) => is_open && css`
		&:before {
			transform: rotate(-90deg);
		}
	`}

`;

const Title = styled.div`
	margin-right: 0.5em;
	display: flex;
	justify-content: center;
    padding: 1em;
	@media (max-width: ${media.desktop}) {
		padding:  0.5em;
	}
`;

interface IAccordionState {
	readonly is_open: boolean;
}

interface IAccordionProps {
	readonly is_open?: boolean;
	readonly is_locked?: boolean;
	readonly is_final?: boolean;
	readonly title: string;
}

class AccordionComponent extends React.Component<IAccordionProps, IAccordionState> {
	public state = {
		is_open: false,
	};

	constructor(props: any) {
		super(props);
		_.bindAll(this, [
			"toggleState",
		]);
	}

	/**
	 * Default state
	 */
	public componentDidMount(): void {
		const { is_open } = this.props;

		if ( is_open ) {
			this.setState({
				is_open: true,
			});
		}
	}

	/**
	 * Toggle accordion state to open
	 * in style it caused to change max-height to 100%
	 */
	public toggleState() {
		const { is_locked } = this.props;

		if ( !is_locked ) {
			this.setState((state: IAccordionState) => ( {
				is_open: !state.is_open,
			} ));
		}

	}

	/**
	 * @ignore
	 */
	public render(): React.ReactNode {

		const { title, children, is_locked, is_final } = this.props;
		const { is_open } = this.state;

		if(is_final && !is_locked) {
			return  null
		}
		return (
			<Wrapper>
				<Title onClick={this.toggleState}>
					<span>{title}</span>
					{!is_locked && !is_final && ( <Arrow is_open={is_open}/> )}
				</Title>

				{!is_final && (
					<AccordionContent is_open={is_open}>
						{children}
					</AccordionContent>
				)}

			</Wrapper>
		);
	}
}

export const Accordion = AccordionComponent;
