import * as _ from 'lodash';
import { observer } from "mobx-react";
import React, { Component } from 'react';
import styled from "styled-components";
import media from "../../assets/css/media";
import theme from "../../assets/css/theme";
import blank_logo from '../../assets/img/icons/blank-logo.svg';
import {
	ImageAsBG as Image,
	flip, ScrollToOnClick
} from '../../components';
import {
	MobxContext,
	IRootStore
} from "../../modules/stores";
import {Rounds} from "../../modules/stores/collections";
import {Match as TMatch} from "../../modules/stores/models/model.match";
import {TPicks} from "../../modules/stores/models/model.picks";
import arrow from '../../assets/img/icons/arrow-right.svg';

const SelectionsWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	padding: 0 2em;
	align-items: center;
	@media (max-width: ${media.desktop}) {
		padding: 0 0.5em 1em;
	}
`;

const ImageWrapper = styled.div`
	margin-right: 1em;
	height: 100%;
	flex: 1;
	max-width: 70px;
	img {
		height: auto;
		max-height: 40px;
		max-width: 60px;	
	}
	
	@media (max-width: ${media.desktop}) {
		margin-right: 0.5em;
		display: flex;
		justify-content: center;
		img {
			max-height: 30px;
			max-width: 25px;
			height: 100%;
		}	
	}
	
	&:last-child {
		margin-right: 0;
	}
	
	${Image} {
		animation: ${flip} 0.2s ease-in;
	}
`;


const Arrow = styled.span`
	position: relative;
	max-width: 50px;
	width: 16px;
	height: 16px;
	cursor: pointer;
	&:before{
		transform: rotate(-90deg);
		content: '';
		display: block;		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url("${arrow}") center no-repeat;
		background-size: 8px;
		transition: all 0.3s ease;
	}
`;

const TitleWrapper = styled.div``;

const Wrapper = styled.div`
	margin-bottom: 1em;
	
	${TitleWrapper}{
		pointer-events: none;
		
		${Arrow}{
			display: none;
		}
	}
	
	@media (max-width: ${media.desktop}){
		&.wrapper-fixed{
			position:fixed;
			background-color:#F2F2F2;
			width:100vw;
			left:0;
			bottom:0;
			z-index:1;
			margin-bottom:0;
			
			${TitleWrapper}{
				pointer-events: all;
				
				${Arrow}{
					display: block;
				}
			}
		}
	}
`;

const Title = styled.p`
	text-transform: uppercase;
	padding: 1em;
	font-size: 13px;
	color: #595959;
	font-family: ${theme.base_font};
	letter-spacing: 2px;
	text-align: center;
	margin-bottom: 1em;
	@media (max-width: ${media.desktop}) {
		padding-bottom: 1.5em;
	}
	
	display: flex;
	align-items: center;
	justify-content: center;
`;

const DrawImage = styled.div`
	flex: 1;
	margin-right: 1em;
	position: relative;
	height: 100%;
	overflow: hidden;
	min-width: 50px;
	min-height: 50px;
	&:after {
		position: absolute;
		content: '';
		background: #E7E7E7;
		height: 1px;
		display: block;
		width: 200%;
		transform-origin: center;
		transform: rotate(-45deg);
		left: -50%;
		top: 50%;
		
	}
	@media (max-width: ${media.desktop}) {
		margin-right: 0.5em;
	}
	
	${Image} {
		max-width: 50%;
		max-height: 50%;
		
		&:first-child {
			position: absolute;
			top: 0;
			left: 0;
		}
		
		&:last-child {
			position: absolute;
			bottom: 0;
			right: 0;
		}
	}
`;

interface IProps {
	className?: string
	refOnClick?: any;
}

@observer
class YourSelections extends Component<IProps> {
	static contextType = MobxContext;
	context!: React.ContextType<typeof MobxContext>;

	/**
	 * Returns round picks by current round
	 */
	public get round_picks() {
		const {rounds: {getCurrentRound: round}, picks: picksModel} = this.injected;

		if (!round) {
			return null;
		}
		const picks: TPicks = picksModel.picks;

		return _.map(round.getMatches, (match: TMatch, key: number) => (

				<ImageWrapper
					key={key}
					onMouseOver={_.partial(match.setHoverEffect, true)}
					onMouseLeave={_.partial(match.setHoverEffect, false)}
				>
					{
						match.isComplete ? (
							<>
								{match.isDraw ? (
									<DrawImage>
										<Image squad_id={match.home_squad_id}/>
										<Image squad_id={match.away_squad_id}/>
									</DrawImage>
								) : (
									<Image squad_id={match.winnerID}/>
								)}
							</>
						) : (
							<>
								{
									picks[match.id] && match.hasSquadByID(picks[match.id].squad_id) ? (
										<Image
											squad_id={picks[match.id].squad_id}/>
									) : (
										picks[match.id] && picks[match.id].is_draw ? (
											<DrawImage>
												<Image height={12} squad_id={match.home_squad_id}/>
												<Image height={12} squad_id={match.away_squad_id}/>
											</DrawImage>
											):(
											<img src={blank_logo} alt=""/>
											)

									)
								}
							</>
						)

					}
				</ImageWrapper>
			)
		);
	}

	public get injected() {
		return this.context as IRootStore;
	}

	public get labelRound() {
		const {rounds: {getCurrentRound: round}} = this.injected;
		if (!round) {
			return ``;
		}
		if (Rounds.final_names[round.id]) {
			return `${Rounds.final_names[round.id]} selections`;
		}
		return `Round ${round.id} ${round.isComplete ? 'results' : 'selections'}`;
	}

	public render() {
		const {rounds: {getCurrentRound: round}, ladder: {is_favorites_fixed}} = this.injected;
		const {refOnClick} = this.props;

		if (!round) {
			return null;
		}
		return (
			<Wrapper {...this.props} className={!is_favorites_fixed ? 'wrapper-fixed' : ''}>
				<TitleWrapper>
					<ScrollToOnClick toRef={refOnClick}>
						<Title>
							Your {this.labelRound} <Arrow/>
						</Title>
					</ScrollToOnClick>
				</TitleWrapper>

				<SelectionsWrapper>
					{this.round_picks}
				</SelectionsWrapper>
			</Wrapper>

		);
	}
}

export default YourSelections;
