import {
	computed,
	action,
	observable,
} from "mobx";
import draw from "../../../assets/img/icons/draw.png";
import gain from "../../../assets/img/icons/Gain.svg";
import triangle_down from "../../../assets/img/icons/triangle_down.svg";
import { Match } from "./model.match";
import { IPick } from "./model.picks";

export class LadderModel {

	public games: number = 0;
	public id: number = 0;
	public name: string = "";
	public position: number = 0;
	public ladder_index: number = 0;
	public odds: number = 0;
	public odds_top8: number = 0;

	@observable public draw: number = 0;
	@observable public loss: number = 0;
	@observable public margin: number = 0;
	@observable public points: number = 0;
	@observable public short_name: string = "";
	@observable public win: number = 0;
	@observable public is_favorite: boolean = false;
	@observable public bye_count: number = 0;
	@observable public form: any = {
		w: 0,
		d: 0,
		l: 0,
	};

	constructor(data: LadderModel) {
		Object.assign(this, data);
	}

	/**
	 * @ignore
	 */
	@computed
	public get WDL() {
		return `${this.win}-${this.draw}-${this.loss}`;
	}

	/**
	 * @ignore
	 */
	@computed
	public get formWDL() {
		return `${this.form.w}-${this.form.d}-${this.form.l}`;
	}

	/**
	 * @ignore
	 */
	@action
	public incrementBye() {
		this.bye_count++;
	}
  // tslint:disable-next-line:completed-docs
  public resetByes() {
    this.bye_count = 0;
  }

	/**
	 * @ignore
	 */
	@action
	public resetForm() {
		this.form = {
			d: 0,
			w: 0,
			l: 0
		}
	}
	/**
	 * @ignore
	 */
	@action
	public incrementFormDraw() {
		this.form.d++;
	}

	/**
	 * @ignore
	 */
	@action
	public incrementFormLoss() {
		this.form.l++;
	}
	/**
	 * @ignore
	 */
	@action
	public incrementFormWon() {
		this.form.w++;
	}
	/**
	 * @ignore
	 */
	@action
	public incrementPoints() {
		const TWO = 2;
		this.points += TWO;
	}
	/**
	 * @ignore
	 */
	@action
	public incrementPointsDraw() {
		this.points += 1;
	}

	/**
	 * @ignore
	 */
	@action
	public incrementWon() {
		this.win++;
	}
	/**
	 * @ignore
	 */
	@action
	public incrementDraw() {
		this.draw++;
	}
	/**
	 * @ignore
	 */
	@action
	public incrementGames() {
		this.games++;
	}
	/**
	 * @ignore
	 */
	@action
	public incrementLost() {
		this.loss++;
	}
	/**
	 * @ignore
	 */
	@action
	public setFavorite(state: boolean) {
		this.is_favorite = state;
	}

	/**
	 * @ignore
	 */
	@action
	public ladderIndex(index: number) {
		this.ladder_index = index;
	}

	/**
	 * @ignore
	 */
	@action
	public setPosition(pos: number) {
		this.position = pos;
	}

	/**
	 * @ignore
	 */
	@action
	public incrementMargin(pick: IPick) {
		const margin = parseInt(Match.draw_items[pick.draw_index], 10);
		this.margin += margin;
	}

	/**
	 * @ignore
	 */
	@action
	public decrementMargin(pick: IPick) {
		const margin = parseInt(Match.draw_items[pick.draw_index], 10);
		this.margin -= margin;
	}

	/**
	 * Returns icons for ladder position changes
	 * img
	 */
	@computed
	public get getIcons() {
		if (!this.ladder_index) {
			return draw;
		}
		if (~this.ladder_index) {
			return gain;
		}
		if (!~this.ladder_index) {
			return triangle_down;
		}

		return undefined;
	}
}
