import * as _ from "lodash";
import * as React from "react";
import styled from "styled-components";

const Button = styled.div`
  cursor: pointer;
  text-align: center;
  width: 100%;
  &:focus {
    box-shadow: none;
    border: none;
    outline: none;
  }
`;

interface IModelScrollComponent {
	readonly children: React.ReactNode;
	readonly toRef: React.RefObject<HTMLDivElement>;
}

class ScrollToOnClickComponent extends React.Component<IModelScrollComponent> {

	/**
	 * Scroll to element from ref filed.
	 * ref should exist in props
	 */
	public scrollToRef = (): void => {
		const { toRef } = this.props;
		const current = _.get(toRef, "current")!;

		try {

			current.scrollIntoView({
				behavior: "smooth",
				block   : "start",
			} );
		} catch (e) {

			try {
				window.scrollTo(0, current.offsetTop);
			} catch (evt2) {
				throw new Error(evt2);
			}
		}
	}

	/**
	 * @ignore
	 */
	public render() {
		const { children } = this.props;
		return (
			<Button
				role="button"
				tabIndex={0}
				onKeyPress={this.scrollToRef}
				onClick={this.scrollToRef}
			>
				{children}
			</Button>
		);
	}
}

export const ScrollToOnClick = ScrollToOnClickComponent;
export default ScrollToOnClick;
