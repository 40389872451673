import styled from "styled-components";
import blank_logo from "../../assets/img/icons/blank-logo.svg";

const image_url = process.env.REACT_APP_IMAGE_SQUADS || "";

interface IOwn {
	readonly squad_id: number;
	readonly background?: string;
}

export const Image = styled.img.attrs(({ squad_id }: IOwn) => ( {
	src      : squad_id ? image_url.replace("--squad--", squad_id.toString()) : blank_logo,
	className: squad_id ? "image" : "none",
	onError  : (e) => {
		e.preventDefault();
		e.stopPropagation();
		// e.currentTarget.src = image_url.replace('<player_id>',  '0')
	},
} ))<IOwn>`
	width: 100%;
	height: 100%;
`;
