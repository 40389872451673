import {
	flow,
	observable,
	action,
	computed,
} from "mobx";
import * as _ from "lodash";

type Prom = () => Promise<any>;

// tslint:disable-next-line
type Class = { new(...args: any[]): any; } | undefined;

interface ICoreCollection {
	api?: Prom | undefined;
	model: Class;
}

abstract class CoreCollection<T> implements ICoreCollection {

	public get api(): Prom | undefined {
		return undefined;
	}

	public get model(): Class {
		return undefined;
	}

	@computed
	public get isEmpty() {
		return !Boolean(this.items.length);
	}

	public static statuses = {
		IDLE    : 0,
		PENDING : 1,
		FINISHED: 2,
		FAILED  : 3,
	};
	@observable public state: number = CoreCollection.statuses.IDLE;

	@observable private items: T[] = [];
	private is_initial: boolean = true;

	@action
	private readonly fetch = flow(function*(this: CoreCollection<T>) {
		const _this = this;

		if ( _this.state !== CoreCollection.statuses.PENDING ) {
			try {
				_this.state = CoreCollection.statuses.PENDING;
				if ( _this.api ) {
					const result = yield _this.api();
					_this.setResult(result);
				}
			} catch ( e ) {

				_this.state = CoreCollection.statuses.FAILED;

			}
		}

	});

	/**
	 * Returns items of current collection
	 */
	public getItems() {
		if ( !this ) {
			return [];
		}

		if ( this.is_initial ) {
			this.fetch();
		}

		return this.items;
	}

	/**
	 * reset collection
	 */
	@action
	public reset(arr: T[]) {
		const result_items = JSON.parse(JSON.stringify(arr));

		this.setResult(result_items);
		return this;
	}

	@action
	private setResult(result_items: T[]) {
		const sort_func = _.get(this, "constructor.sort");
		const transform_result = _.get(this, "constructor.transform");

		this.items = [];

		let result = [];
		if ( result_items.length ) {

			if ( sort_func ) {
				result = result_items.sort(sort_func);
			} else {
				result = result_items;
			}

			if ( transform_result ) {
				result = transform_result(result);
			}

			if ( this.model ) {
				const model = this.model;
				this.items = result.map((item: T) => new model(item));
			} else {
				this.items = result;
			}

		}

		this.is_initial = false;

		this.state = CoreCollection.statuses.FINISHED;

	}

}

export default CoreCollection;
