import { observer } from "mobx-react";
import React, { Component } from 'react';
import styled from "styled-components";
import { ImageAsBG } from '../../components';
import {
	MobxContext,
	IRootStore
} from "../../modules/stores";
import * as _ from 'lodash';


const ByesSquadsWrapper = styled.div`
	padding: 1em;
	background: #fff;
	margin-top: 10px;
	box-shadow: 0 0 14px 0 rgba(0,0,0,0.1);
	
	img {
		flex: 1;
	}
	
	p {
		text-align: center;
		font-size: 13px;
		letter-spacing: 2px;
		color: #595959;
		text-transform: uppercase;
		margin-bottom: 1em;
	}
`;

const ByesSquads = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

@observer
class ByeSquads extends Component {
	static contextType = MobxContext;
	context!: React.ContextType<typeof MobxContext>;

	public get injected() {
		return this.context as IRootStore;
	}

	render() {

		const { rounds: { getCurrentRound: current_round } } = this.injected;

		if ( !current_round ) {
			return null;
		}
		const squads = current_round.bye_squads;


		return squads.length ? (
			<ByesSquadsWrapper>
				<p>
					Byes
				</p>
				<ByesSquads>
					{_.map(squads, (squad: number, index: number) =>
						<ImageAsBG height={60} key={index} squad_id={squad}/>
					)}
				</ByesSquads>
			</ByesSquadsWrapper>
		) : null;
	}
}

export default ByeSquads;