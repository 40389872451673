import * as React from 'react';
import {IRootStore, MobxContext} from "../../modules/stores";
import * as qs from "qs";
import {get} from 'lodash';
import {getUserObjectFromStorage} from "../../modules/utils/user";


class AnaliticsComponent extends React.Component {
	static contextType = MobxContext;
	context!: React.ContextType<typeof MobxContext>;

	public get injected() {
		return this.context as IRootStore;
	}

	public componentDidMount() {
		const user = getUserObjectFromStorage();
		const url_string = window.location.search;
		const url = qs.parse(url_string, {
			ignoreQueryPrefix: true,
			decoder          : (e: any) => decodeURIComponent(e),
		});

		const source: string = url.source || "";
		const { rounds } = this.injected;

		const round = rounds.getCurrentRound;

		if (user && round){
			const nrl_id = get(user, 'sub', false) || get(user, 'nrl_account_id', false);
			if (nrl_id){
				this.sendAnalitics(nrl_id, source, get(round, 'id', 27));
			}
		}
	}

	render() {
		return null;
	}

	private sendAnalitics(nrl_id: number, source?: string, round_id?:number){

		window.dataLayer.push({
			'event':'page_info',
			'source_platform': source,
			'nrl_id': nrl_id,
			'round_id': round_id
		});
	}
}

export const Analitics = AnaliticsComponent;
export default Analitics;