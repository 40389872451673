import * as _ from "lodash";
import React from "react";
import styled, { css } from "styled-components";
import media from "../../assets/css/media";
import theme from "../../assets/css/theme";
import left_arrow from "../../assets/img/icons/left-arrow.svg";
import external_link from "../../assets/img/icons/external_link.svg";
import left_arrow_green from "../../assets/img/icons/left-arrow-green.svg";
import menu_icon_close from "../../assets/img/icons/menu-icon-close.svg";
import menu_icon from "../../assets/img/menu-icon.svg";
import nrl_logo from "../../assets/img/nrl-logo.svg";

import { Content } from "../Content";
import {IRootStore, MobxContext} from "../../modules/stores";
import Storage from "../../modules/utils/Storage";
import {observer} from "mobx-react";

const HeaderTagLine = styled.div`

`;

const HeaderWrapper = styled.div`
	width: 100%;
	background-color: #0C0C0C;
`;

const HeaderContent = styled(Content)`
	margin: 0 auto;
	display: flex;
	align-items: center;
	padding: 1.25em;
	max-width: 1400px;
	&> a {
		height: 60px;
		margin: 0 auto;
		img {
			max-height: 60px;
		}
	}

	position: relative;

	@media (max-width: ${media.desktop}) {
		padding: 5px 0;
		img {
			max-height: 34px;
		}
	}
`;

const HeaderContentLite = styled(Content)`
	margin: 0 auto;
	display: flex;
	align-items: center;
	padding: 1em 0;
	justify-content: space-between;
`;

const HeaderWrapperLite = styled(HeaderWrapper)`
	width: 100%;
	background-color: #fff;
	box-shadow: 0 0 14px 0 rgba(0,0,0,0.1);
	padding: 0;
`;

const Title = styled.div`
	font-family: ${theme.base_font};
	font-weight: 500;
	letter-spacing: 2px;
	color: #222222;
	font-size: 2em;
	text-transform: uppercase;
	text-align: left;
	padding: 0.125em 0;
	span {
		color: #00CF5D;
	}

	@media (max-width: ${media.desktop}) {
		padding: 0.125em 10px;
		font-size: 1.625em;
	}
`;

interface IOpen {
	is_open: boolean;
}

const MenuIcon = styled.div<IOpen>`
	position: absolute;
	left: 0;
	top: 0;
	width: 4em;
	background: url("${menu_icon}");
	background-size: 30px;
	height: 100px;
	cursor: pointer;
	pointer-events: all;
	background-position: 2em center;
	background-repeat: no-repeat;
	border-bottom: 1px solid #222;
	z-index: 9;
	@media (max-width: ${media.desktop}) {
		width: 100%;
		height: 44px;
		background-size: 22px;
		background-position: 20px center;
	}

	transition: all 0.3s ease;

	${({ is_open }) => is_open && css`
		background-image: url("${menu_icon_close}");
	`}
`;

const Menu = styled.ul<IOpen>`
	background: #0C0C0C;
	height: 100%;
	padding: 100px 0 50px;
	font-family: ${theme.base_font};
	font-size: 11px;
	line-height: 1.5;
	letter-spacing: 2.75px;
	font-weight: 500;
	text-transform: uppercase;
	max-width: 0;
	transition: max-width 0.3s ease, visibility 0.3s ease;
	pointer-events: all;
	overflow: hidden;
	visibility: hidden;
	position: relative;
	${({ is_open }) => is_open && css`
		display: block;
		transition: max-width 0.3s ease, visibility 0.3s ease;
		max-width: 100%;
		visibility: visible;
	`};

	@media (max-width: ${media.desktop}) {
		width: 100%;
		padding: 50px 0 50px;
		border-top: 1px solid #222222;
	}
`;

const Item = styled.a`
	color: #fff;
	padding: 0.825em 2em;
	display: block;
	text-decoration: none;
	border-left: 4px solid transparent;
	background-position: 0 0;
 	background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 50%, #222 50%);
  	transition: background-position 0.5s ease-out 0s;
 	background-size: 200.22% auto;
	&:first-child {
		border-left: 4px solid #00CF5D;
		background: #222;
	}
	&.hover-fill{
		&:hover {
		  background-position: -99.99% 0;
		  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 50%, #222 50%);
			border-left: 4px solid #00CF5D;
		}
	}

`;

const ExternalLink = styled.img`
	margin-left: 10px !important;
`;
const MenuWrapper = styled(HeaderContent)<IOpen>`
	background: transparent;
	position: absolute;
	left: 0;
	right: 0;
	padding: 0;
	height: 100%;
	top: 0;
	z-index: 5;
	pointer-events: none;
	${({ is_open }) => is_open && css`

		position: fixed;

		@media( max-width: ${media.desktop} ) {
			padding: 0;
		}
	`}
`;

const NRLHome = styled(Item)`
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 1px;
	background: url("${left_arrow}") left center no-repeat;
	background-size: 8px;
	padding-left: 1.5em;
	margin-top: 1em;
	
	text-transform: initial;
	background: #222;
	&:hover {
		// background-image: url("${left_arrow_green}");
	}
`;

const MyAccount = styled(Item)`
		background-position: 0 0;
 	background-image: linear-gradient(to right, #222 50%, rgba(255, 255, 255, 0) 50%);
  	transition: background-position 0.5s ease-out 0s;
 	background-size: 200.22% auto;
	color: #FFFFFF;
	position: absolute;
	bottom: 5em;
	width: 100%;
	left: 0;
	padding: 1em;
	font-family: ${theme.base_font};
	letter-spacing: 1px;
	text-align: center;
	cursor: pointer;
	& > div {
		width: 38px;
		height: 38px;
		border-radius: 50%;
		background: #0C0C0C;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 13px;
		margin: 0 auto 1em;
	}

	& > p {
		font-size: 12px;
		font-weight: 500;
		text-transform: initial;
	}
		&:hover {
		  background-position: -99.99% 0;
		  background-image: linear-gradient(to right,  #222 50%, rgba(255, 255, 255, 0) 50%);
		
		}	

`;

const LogOut = styled.div`
	background: #222;
	color: #FFFFFF;
	position: absolute;
	bottom: 1em;
	width: 100%;
	left: 0;
	padding: 1em;
	font-family: ${theme.base_font};
	letter-spacing: 1px;
	text-align: center;

		&:hover {
		//color: #00CF5D;
		cursor: pointer !important;
	}
`;

const PresentedBySection = styled.a`
	font-family: ${theme.base_font};
	font-weight: 400;
	font-size: 1em;
	color: #595959;
	display: flex;
	align-items: center;
	height: 100%;
	text-decoration: none;
	img{
		margin-left: 10px;
		height: 50px;
		padding-right: 10px;
	}
	@media (max-width: ${media.desktop}) {
		font-size: 0.8em;
		white-space: nowrap;
	}
`;

interface IState {
	is_open: boolean;
}
@observer
class HeaderComponent extends React.Component<any, IState> {

	public get injected() {
		return this.context as IRootStore;
	}
	public get userInitials() {
		const user_from_storage = (Storage.get("user"));
		if (user_from_storage) {
			const user_data = JSON.parse(user_from_storage);
			const first_name = user_data.given_name_initial || user_data.given_name;
			const last_name = user_data.family_name_initial || user_data.family_name;

			if (!!first_name && !!last_name) {
				return first_name.charAt(0) + last_name.charAt(0);
			}
		}
		return "";
	}
	public static contextType = MobxContext;
	public state = {
		is_open: false,
	};
	public context!: React.ContextType<typeof MobxContext>;
	constructor(props: any) {
		super(props);
		_.bindAll(this, [
			"toggleState",
			"logOut",
		]);
	}

	public render(): React.ReactNode {
		const { is_open } = this.state;
		const { user } = this.injected;
		return (
			<>
				<HeaderTagLine>
					<HeaderWrapper>
						<HeaderContent>
							<a href={"//nrl.com"} target={"_blank"}>
								<img src={nrl_logo} alt="Game Logo NRL"/>
							</a>
						</HeaderContent>

					</HeaderWrapper>

					<MenuWrapper is_open={is_open}>
						<MenuIcon is_open={is_open}
						          onClick={this.toggleState}/>
						<Menu is_open={is_open}>
							<Item href="/">
								Ladder Predictor
							</Item>
							<Item className={'hover-fill'} href="//fantasy.nrl.com" target={"_blank"}>
								Fantasy
								<ExternalLink src={external_link} alt="External Link" />
							</Item>
							<Item className={'hover-fill'} href="//tipping.nrl.com" target={"_blank"}>
								Tipping
								<ExternalLink src={external_link} alt="External Link" />
							</Item>

							<NRLHome href="//nrl.com">
								NRL Home
							</NRLHome>
							{user.is_logged_in && (
									<MyAccount
										href="//nrl.com/account"
										target="_blanc"
									>
										<div>{this.userInitials}</div>
										<p>
											My Account
										</p>
									</MyAccount>
								)
							}
							{user.is_logged_in && (
								<LogOut onClick={this.logOut}>
									LOG OUT
								</LogOut>
							)}
						</Menu>
					</MenuWrapper>

					<HeaderWrapperLite className="hidden-mobile-landscape">
						<HeaderContentLite>
							<Title>
								Ladder Predictor
							</Title>
							{/**comment: Left it here just incase we will have another sponsor */}
							<PresentedBySection 
								href="#" 
								target="_blank"
							> 
							</PresentedBySection>
						</HeaderContentLite>
					</HeaderWrapperLite>
				</HeaderTagLine>

			</>

		);
	}

	private toggleState() {
		this.setState((state: IState) => ( {
			is_open: !state.is_open,
		} ));
	}

	private logOut() {
		const { user } = this.injected;
		user.logout();
		this.setState((state: IState) => ( {
			is_open: !state.is_open,
		} ));

	}

}

export const Header = HeaderComponent;
export default Header;
