import {
	observable,
	action
} from "mobx";
import CoreCollection from "../../core/collection";
import Api from "../../utils/Api";
import { Match } from "../models/model.match";
import * as _ from 'lodash';

/**
 * Squads collection
 */
export class FinalsMatches extends CoreCollection<Match> {

	@observable public executed: boolean = false;

	/**
	 * @ignore
	 */
	public get api() {
		return Api.JSON.finals;
	}

	/**
	 * @ignore
	 */
	public get model() {
		return Match;
	}

	/**
	 * @ignore
	 */
	@observable
	public getMatchById(id: number) {
		const matches = this.getItems();
		return matches.find((squad: Match) => squad.id === id);
	}


	/**
	 * @ignore
	 */
	@action
	public findMatch(squads: number[], range:  number[]){
		const matches = this.getItems();

		return _.find(matches, (match: Match) => {

			return !_.differenceBy([ match.home_squad_id, match.away_squad_id ], squads).length && _.inRange(match.match, range[0], range[1]);
		});
	}
}
