import moment from "moment";
import React, { Component } from "react";
import styled, { css } from "styled-components";
import media from "../../../../assets/css/media";
import { Match } from "../../../../modules/stores/models/model.match";

interface IComplete {
	is_complete: boolean;
}

const MatchDataWrapper = styled.div<IComplete>`
	text-align: center;
	flex: 1 1 100%;
	max-width: ${({ is_complete }) => is_complete ? "40%" : "50%"};
`;

const MiddleData = styled.p<{ is_complete?: boolean }>`
	margin: 0.5em auto;
	font-size: 20px;
	color: #222222;
	font-weight: bold;

	@media (max-width: ${media.desktop}) {
		font-size: 14px
	}

	${({ is_complete }) => is_complete && css`
		color: #595959;
		font-size: 11px;
		font-weight: 500;
		letter-spacing: 1.1px;
		margin: 1em 0;

		@media (max-width: ${media.desktop}) {
			font-size: 11px
		}
	`};
`;

const TopData = styled.p`
	text-transform: uppercase;
	color: #595959;
	font-size: 13px;
	letter-spacing: 2px;
	margin-bottom: 1.5em;
	@media (max-width: ${media.desktop}) {
		font-size: 9px;
		margin-bottom: 1em;
	}
`;
const PostPoned = styled(TopData)`
	margin-bottom: 0;
`;
const Venue = styled.p`
	font-size: 14px;
	color: #222222;
	margin-bottom: 0.5em;
	@media (max-width: ${media.desktop}) {
		font-size: 9px;
	}
`;

interface IProp {
	readonly match: Match;
}

class MatchData extends Component<IProp> {
	public render() {
		const { match } = this.props;
		const is_complete = match.isComplete;

		if(!!match.is_postponed ) {
			return  (
				<MatchDataWrapper
					is_complete={false}
				>
					<PostPoned>POSTPONED</PostPoned>
				</MatchDataWrapper>
			)
		}
		return match ? (
			<MatchDataWrapper is_complete={is_complete}>
				{match.date ? (
					<>
						<TopData>{moment(match.date).format("dddd Do MMMM")}</TopData>
						<MiddleData is_complete={is_complete}>
							{is_complete ? "FULL TIME" : moment(match.date).format("h:mma")}
						</MiddleData>
					</>
				) : (
					<>
						<TopData>--</TopData>
						<MiddleData is_complete={is_complete}>
							--
						</MiddleData>
					</>
				)}

				<Venue>{match.venue_name || "TBA"}</Venue>
			</MatchDataWrapper>
		) : null;
	}
}

export default MatchData;
