import { observer } from "mobx-react";
import React, { Component } from "react";
import styled, { css } from "styled-components";
import media from "../../assets/css/media";
import { GAME_YEAR, SQUADS_THEME } from "../../modules/constants/squad";
import { MobxContext, IRootStore } from "../../modules/stores";
import { Match } from "../../modules/stores/models/model.match";

const ResultsWrapper = styled.div<{ squad_id: number | "default_theme" }>`
	font-size: 40px;
	letter-spacing: 2px;
	color: #ffffff;
	font-weight: 500;
	width: 100%;
	text-align: center;
	padding: 3em 0;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	&:before {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		content: "";
		opacity: ${({ squad_id }) => (squad_id === "default_theme" ? 1 : 0.1)};
		display: block;
	}
	> span {
		z-index: 1;
	}
	${({ squad_id }) =>
		squad_id &&
		css`
			background: ${SQUADS_THEME[squad_id]? SQUADS_THEME[squad_id].color :  SQUADS_THEME.default_theme.color};

			&:before {
				background: url(${SQUADS_THEME[squad_id]? SQUADS_THEME[squad_id].image : SQUADS_THEME.default_theme.image}) ${squad_id === 500723? "top" : "center"}
					no-repeat;
				background-size: 75%;
			}
		`};
	

	@media (max-width: ${media.mobile}) {
		font-size: 26px;
		min-height: 300px;
	}
`;

@observer
class FinalResult extends Component {
	static contextType = MobxContext;
	context!: React.ContextType<typeof MobxContext>;

	public get injected() {
		return this.context as IRootStore;
	}

	public get defaultBlock() {
		return (
			<ResultsWrapper squad_id="default_theme">
				<span>Select your winner above!</span>
			</ResultsWrapper>
		);
	}

	public get winnerBlock() {
		const { rounds, squads } = this.injected;
		const finalMatch = rounds.finalMatch;

		const match = finalMatch as Match;

		if (!match) {
			return this.defaultBlock;
		}

		const is_home_won = match.home_score > match.away_score;
		const squad_id = is_home_won
			? match.home_squad_id
			: match.away_squad_id;

		const squad = squads.getSquadById(squad_id);
		return squad ? (
			<ResultsWrapper squad_id={squad_id}>
				<span>
					The {GAME_YEAR} premiers are the {squad.name}
				</span>
			</ResultsWrapper>
		) : null;
	}

	public render() {
		const { picks, rounds, squads } = this.injected;
		const round = rounds.getCurrentRound;
		const finalMatch = rounds.finalMatch;

		const match = finalMatch as Match;
		const is_final = round && round.is_grand_final;
		const is_round_complete = round && round.isComplete;

		if (!match || !is_final) {
			return null;
		}

		const pick = picks.getByID(match.id);

		/**
		 *  In order to get winner squad_id, we should know which team is won
		 * is_home_won = match.home_score > match.away_score
		 * won_squad_id = is_home_won ? match.home_squad_id : match.away_squad_id
		 */
		if (!pick) {
			return is_round_complete ? this.winnerBlock : this.defaultBlock;
		}

		const is_squad_exist =
			match.hasSquadByID(pick.squad_id) && match.hasSquads;
		const squad = squads.getSquadById(pick.squad_id);

		console.log(squad)
		return pick && is_squad_exist && squad ? (
			<ResultsWrapper squad_id={squad.id}>
				{match.isComplete
					? this.labelComplete(match)
					: `Your ${GAME_YEAR} predicted Premiers are the ${squad.name}`}
			</ResultsWrapper>
		) : (
			this.defaultBlock
		);
	}

	public labelComplete(match: Match) {
		const { squads } = this.injected;
		const squad_id = match.winnerID;
		const squad = squads.getSquadById(squad_id);
		return squad
			? `Congratulations to the ${squad.name}! The ${process.env.REACT_APP_GAME_YEAR} NRL Premiers!`
			: "";
	}
}

export default FinalResult;
