import { IShare } from "../../types";
import {map, get, identity,find } from "lodash";
import { Match } from "../../stores/models/model.match";
const FB_SHARE_URL = process.env.REACT_APP_FB_SHARE_URL || "";
const protocol = window.location.protocol;

/**
 * in order to reduce the size of the string for sharing
 */
const last_four_number = -4;
const getTimestamp = () => Date.now().toString().substr(last_four_number);

const getEncodedJson = (object: any) => btoa(JSON.stringify(object));
const getLinkForShare = (object: any, type: string) =>
	`${protocol}${FB_SHARE_URL}${type}/${getEncodedJson(object)}`;

const share_messages = {
	general:
		"I just voted in The Sun's Love Island daily poll, make sure you do too! #SunLoveIsland",
};

export const share = (data: IShare) => {
	if (data.sc === "tw") {
		shareTwitter(data);
	}

	if (data.sc === "fb") {
		shareFB(data);
	}
};

export const shareFB = (data: IShare) => {
	const { sc, section } = data;
	const FB_API = get(window, "FB");
	const object: any = {
		sc,
		t: getTimestamp(),
	};

	FB_API.ui(
		{
			method: "share",
			display: "popup",
			href: getLinkForShare(object, section),
		},
		identity
	);
};

export const shareTwitter = (data: IShare) => {
	const { sc, section } = data;
	const object: any = {
		sc,
		t: getTimestamp(),
	};

	const href = getLinkForShare(object, section);

	window.open(
		"https://twitter.com/share?url=" +
			encodeURIComponent(href) +
			"&text=" +
			encodeURIComponent(share_messages[section]),
		"twitter-share-dialog",
		"width=626,height=436"
	);
};

/*
 * Refer to F2P1-20699 for details
*/ 
const getInnerValues =(pick : string,matchId: number,matches :Match[]) :any=>{
	const  data :any = find(matches,{'id': matchId});
	const CODE = pick.slice(0,1);
	const STR_POS = 2;
	const draw_index =  pick.slice(1,STR_POS);
	switch(CODE){
		case 'a': 
			return {
				id:matchId,
				squad_id:data.away_squad_id,
				is_draw:false,
				round_id:data.round,
				is_finals:false,
				 draw_index
			}
		case 'h': 
			return {
				id:matchId,
				squad_id:data.home_squad_id,
				is_draw:false,
				round_id:data.round,
				is_finals:false,
				 draw_index,
			}	
		case 'd': 
		   return {
				id:matchId,
			   is_draw:true,
			   round_id:data.round,
			   is_finals:false
		   }		
	}
}

export const convertPicksStringToObj = (picks :string,matches:Match[]) =>{
	const parsedPicks = picks.match(/.{1,6}/g);
	const objOutput :any= {}
	map(parsedPicks,(pick) => {
			const STR_POS = 2;
			const START_OF_MATCHID=111;
			const matchId = parseInt(START_OF_MATCHID+pick.slice(STR_POS),10);
			const childMatch =  getInnerValues(pick ,matchId,matches);
		objOutput[matchId] = childMatch
	})
	return objOutput;
}

/*
 * Refer to F2P1-20838 for details
*/ 
const generatePickCode =(values :any,matches:Match[]) =>{
	const DEFAULT_DRAW_INDEX=0;
	const {id,squad_id,draw_index} = values
	const  data :any = find(matches,{'id': id});
	const STR_POS = 3;
	/*
	* Get last 4 characters of matchID as all start with 111
	*/ 
	const shortId = id.toString().slice(STR_POS) 
	let resultingPattern = "";
	if(squad_id){
		if(squad_id === data.home_squad_id){
			resultingPattern = 'h'+draw_index+shortId
		}
		if(squad_id === data.away_squad_id){
			resultingPattern = 'a'+draw_index+shortId
		}
	}
	else{
		resultingPattern = 'd'+DEFAULT_DRAW_INDEX+shortId
	}
	return resultingPattern
}

export const convertPicksoObjToString = (localPicks :any,matches:Match[]) =>{
	
	let result = "";
	map(localPicks,(value) =>{
		const text2 = generatePickCode(value,matches)
		result = result.concat(text2);
	})
	 return result;
}