import styled from "styled-components";

export interface IButtonInterface {
	readonly transparent?: boolean;
	readonly disabled?: boolean;
	readonly margin?: string;
	readonly max_width?: string;
	readonly color_bg?: string;
	readonly is_locked_state?: boolean;
	readonly color_border?: string;
	readonly color_text?: string;
}

export const RegularButton = styled.button<IButtonInterface>`
	padding: 1em;
	background: #000;
	color: #fff;

	a {
		color: inherit;
		text-decoration: none;
		width: inherit;
		height: inherit;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

export default RegularButton;
