import * as _ from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import styled, { css } from "styled-components";
import media from "../../assets/css/media";
import theme from "../../assets/css/theme";
import arrow from "../../assets/img/icons/arrow-right.svg";
import cross from "../../assets/img/icons/cross.svg";

import { Title } from "../../components";
import { IRootStore, MobxContext } from "../../modules/stores";
import { Rounds } from "../../modules/stores/collections";
import { Round } from "../../modules/stores/models/model.round";

const TagLineWrapper = styled.h2`
	width: 100%;
	align-items: center;
`;

const RoundsSelectorWrapper = styled.div`
	display: flex;
	flex-wrap: nowrap;
	border: 1px solid #dcdcdc;
	border-radius: 4px;
	background-color: #f6f6f6;
	width: 100%;
	& > div {
		border-right: 1px solid #dcdcdc;
		text-align: center;
		padding: 1em;
		&:last-child {
			border: none;
		}
	}

	@media (max-width: ${media.desktop}) {
		max-width: 100%;
		margin: 10px 0;
		& > div {
			padding: 1.25em;
		}
	}
`;

const RoundName = styled.div`
	flex: auto;
	font-size: 0.875em;
	color: #595959;
	font-family: ${theme.base_font};
	letter-spacing: 1px;
	cursor: pointer;

	@media (max-width: ${media.desktop}) {
		font-size: 0.75em;
	}
`;

const Actions = styled.div`
	display: flex;
	margin-bottom: 20px;
	justify-content: space-between;
	@media (max-width: ${media.desktop}) {
		flex-wrap: wrap;
	}
`;

const Action = styled.div`
	padding: 1em 0.5em;
	border: 0.125rem solid #dcdcdc;
	text-transform: uppercase;
	border-radius: 5px;
	color: #222222;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 2.5px;
	cursor: pointer;
	margin-right: 0.5em;
	justify-content: center;
	display: flex;
	align-items: center;
	&:last-child {
		margin-right: 0;
	}

	@media (max-width: ${media.desktop}) {
		width: 100%;
		transition: all 0.3s ease-in;
		flex: 1;

		@media (max-width: ${media.mobile}) {
			font-size: 12px;
		}
	}

	@media (min-width: ${media.desktop}) {
		min-width: 105px;
	}

	&:hover {
		border-color: #222222;
		color: #222222;
	}
`;

interface IArrow {
	has_round: boolean;
}

const Arrow = styled.div<IArrow>`
	cursor: ${({ has_round }) => (has_round ? "pointer" : "not-allowed")};
	position: relative;
	max-width: 50px;
	width: 100%;
	&:before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url("${arrow}") center no-repeat;
		background-size: 8px;
	}

	&:first-child {
		&:before {
			transform: rotate(180deg);
		}
	}

	@media (max-width: ${media.desktop}) {
		&:before {
			background-size: 6px;
		}
	}
`;

interface IDisabled {
	disabled: boolean;
}

const AutoFill = styled(Action)<IDisabled>`
	${({ disabled }) =>
		disabled &&
		css`
			color: #dcdcdc;
			cursor: not-allowed;
		`};

	margin-left: auto;

	@media (max-width: ${media.desktop}) {
		margin-left: 0;
	}
`;

const Clear = styled(Action)<IDisabled>`
	${({ disabled }) =>
		disabled &&
		css`
			color: #dcdcdc;
			cursor: not-allowed;
		`};

	@media (max-width: ${media.desktop}) {
	}
`;

const Reset = styled(Action)<IDisabled>`
	cursor: not-allowed;
	color: #c72008;
	border-color: #c72008;
	${({ disabled }) =>
		!disabled &&
		css`
			border: 1px solid #c72008;
			color: #c72008;
			cursor: pointer;
		`};
	&:hover {
		border-color: #b31a1a;
		color: #b31a1a;
	}
	@media (max-width: ${media.desktop}) {
	}
`;

const DropDownSelector = styled.div`
	position: relative;
	max-width: 225px;
	width: 100%;
	@media (max-width: ${media.desktop}) {
		max-width: 100%;
		margin: 10px 0;
	}
`;

const RoundsSelectorFullView = styled.div<{ is_open: boolean }>`
	position: absolute;
	width: 420px;
	top: 100%;
	left: 0;
	background: #fff;
	z-index: 8;
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
	max-height: 0;
	overflow-y: scroll;
	transition: all 0.3s ease;
	margin-top: 4px;

	${({ is_open }) =>
		is_open &&
		css`
			max-height: 560px;
			@media (max-width: ${media.desktop}) {
				position: fixed;
				top: 0;
				left: 0;
				height: 100%;
				max-height: none;
				width: 100%;
				margin-top: 0;
			}
		`}
`;

const RoundItemsFullViewWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	padding: 20px;
	margin-top: 4px;
`;

const RoundItemFullViewWrapper = styled.li`
	flex: 1 1 100%;
	min-width: 33%;
	max-width: 33%;
	list-style: none;
	height: 4em;
	padding: 0.5em;

	@media (max-width: ${media.desktop}) {
		min-width: 6.25em;
	}

	@media (max-width: ${media.mobile}) {
		max-width: 50%;
	}
`;

const RoundItemFullView = styled.button<{ is_current: boolean }>`
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	border: 1px solid #efefef;
	font-size: 14px;
	cursor: pointer;
	${({ is_current }) =>
		is_current &&
		css`
			border: 2px solid #000;
		`}

	&:hover {
		border: 2px solid #008540;
	}
`;

const CloseIcon = styled.button`
	width: 15px;
	height: 15px;
	background: url(${cross}) center no-repeat;
	background-size: 100%;
	border: none;
	outline: none;
`;
const RoundSelectorTagLine = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #fff;
	font-size: 14px;
	border-bottom: 1px solid #008540;
	background: #0d0d0d;
	height: 44px;
	width: 100%;
	padding: 0 20px;
	@media (min-width: ${media.desktop_min}) {
		display: none;
	}

	span {
		text-transform: uppercase;
		letter-spacing: 1.75px;
	}
`;

const ClickWatcher = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 5;
	top: 0;
	left: 0;
`;

interface IProps {
	with_title: boolean;
	refElem?: any;
}

interface IState {
	is_open: boolean;
}

@observer
class FixtureTagLineComponent extends React.Component<IProps, IState> {
	public state = {
		is_open: false,
	};

	constructor(props: any) {
		super(props);
		_.bindAll(this, [
			"changeCurrentRound",
			"autoFill",
			"changeState",
			"clear",
			"reset",
		]);
	}

	static contextType = MobxContext;
	context!: React.ContextType<typeof MobxContext>;

	public get injected() {
		return this.context as IRootStore;
	}

	public get round_selector() {
		const { rounds } = this.injected;

		const round_id = _.get(rounds.getCurrentRound, "id", 0);

		const getRoundLabel = (round_id: number) =>
			Rounds.final_names[round_id] || `Round ${round_id}`;
		if (!round_id) {
			return null;
		}

		const round_index = rounds.getIndexByID(round_id);
		const next_round = rounds.getByIndex(round_index + 1);
		const prev_round = rounds.getByIndex(round_index - 1);

		const round_label = getRoundLabel(round_id);
		const all_round = rounds.getItems();
		return (
			<DropDownSelector>
				<RoundsSelectorWrapper>
					<Arrow
						has_round={Boolean(prev_round)}
						onClick={_.partial(this.changeCurrentRound, prev_round)}
					/>
					<RoundName
						onClick={_.partial(
							this.changeState,
							!this.state.is_open
						)}
					>
						{round_label}
					</RoundName>
					<Arrow
						has_round={Boolean(next_round)}
						onClick={_.partial(this.changeCurrentRound, next_round)}
					/>
				</RoundsSelectorWrapper>
				{this.state.is_open && (
					<ClickWatcher
						onClick={_.partial(this.changeState, false)}
					/>
				)}
				<RoundsSelectorFullView is_open={this.state.is_open}>
					<RoundSelectorTagLine>
						<span>round</span>
						<CloseIcon
							onClick={_.partial(this.changeState, false)}
						/>
					</RoundSelectorTagLine>
					<RoundItemsFullViewWrapper>
						{_.map(
							all_round.reverse(),
							(round: Round, index: number) => (
								<RoundItemFullViewWrapper key={index}>
									<RoundItemFullView
										onClick={_.partial(
											this.changeCurrentRound,
											round
										)}
										is_current={_.eq(round_id, round.id)}
									>
										{getRoundLabel(round.id)}
									</RoundItemFullView>
								</RoundItemFullViewWrapper>
							)
						)}
					</RoundItemsFullViewWrapper>
				</RoundsSelectorFullView>
			</DropDownSelector>
		);
	}

	/**
	 * @ignore
	 **/
	public render() {
		const {
			rounds: { getCurrentRound: round },
			picks,
		} = this.injected;
		const { with_title, refElem } = this.props;
		return round ? (
			<TagLineWrapper ref={refElem}>
				{with_title && (
					<Title hide_mobile={true}>
						<p>Make your Selections</p>
					</Title>
				)}

				<Actions>
					{this.round_selector}
					<AutoFill
						onClick={this.autoFill}
						disabled={round.isFullFilled}
					>
						<span>auto fill</span>
					</AutoFill>
					<Clear onClick={this.clear} disabled={round.isEmptyFill}>
						<span>clear rnd</span>
					</Clear>
					<Reset onClick={this.reset} disabled={picks.isEmpty}>
						<span>reset all</span>
					</Reset>
				</Actions>
			</TagLineWrapper>
		) : null;
	}

	private changeCurrentRound(round: Round | undefined) {
		this.changeState(false);
		if (round) {
			const { rounds } = this.injected;
			rounds.changeCurrentRound(round.id);
			window.dataLayer.push({
				event: "v_page_view",
				page_name: "Round" + round.id,
				page_path: "/round-" + round.id,
			});
		}
	}

	private autoFill() {
		const {
			rounds: { getCurrentRound: round },
			picks,
			ladder,
		} = this.injected;

		if (round && !round.isFullFilled) {
			// round.setCurrentLadder(ladder.actual_ladder);
			picks.setPicksAutoFill(round.setAutoFillObject(ladder));
			round.fillMatchIDS();
		}
	}

	private clear() {
		const { rounds, picks } = this.injected;
		const round = rounds.getCurrentRound;

		if (round && !round.isEmptyFill) {
			if (round.isFinal) {
				const rounds_after = rounds.getRoundsAfter(round.id);
				const matches = _.flatten(
					_.map(rounds_after, (round: Round) =>
						toJS(round.final_matches)
					)
				);
				const match_ids = _.map(matches, "id");

				picks.deletePicks(match_ids);
				_.invoke(rounds_after, "fillMatchIDS");
			}

			const match_ids = _.map(round.getMatches, "id");
			picks.deletePicks(match_ids);
			round.fillMatchIDS();
		}
	}

	private reset() {
		const {
			picks,
			rounds: { getCurrentRound: round },
		} = this.injected;

		if (
			round &&
			window.confirm(
				"Are you sure you want to reset all of your predictions?"
			)
		) {
			picks.clearAll();
			round.fillMatchIDS();
			window.location.reload();
		}
	}

	private changeState(state: boolean) {
		this.setState({
			is_open: state,
		});
	}
}

export const FixtureTagLine = FixtureTagLineComponent;

export default FixtureTagLine;
