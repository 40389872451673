import React from "react";
import styled from "styled-components";
import media from "../../assets/css/media";
import theme from "../../assets/css/theme";
import nrl_logo from "../../assets/img/nrl-logo.svg";
import { Content } from "../Content";
import fb from "../../assets/img/icons/fb.svg";
import inst from "../../assets/img/icons/inst.svg";
import tw from "../../assets/img/icons/tw.svg";
import wa from "../../assets/img/icons/WhatsApp.svg";
import genius from "../../assets/img/genius.png";
import { GAME_YEAR } from "../../modules/constants/squad";

const FooterTagLine = styled.div`
	font-family: ${theme.base_font};
`;

const FooterWrapper = styled.div`
	width: 100%;
	background-color: #0c0c0c;
	padding: 5.625em 0;
	@media (max-width: ${media.desktop}) {
		padding: 30px 10px 120px;
	}
`;

const FooterContent = styled(Content)`
	display: flex;
	justify-content: space-between;
	color: #fff;

	padding-bottom: 14px;
`;

const FooterContentWithBorder = styled(FooterContent)`
	border-bottom: 1px solid #414141;
	margin-bottom: 33px;

	@media (max-width: ${media.desktop}) {
		border: none;
		margin: 0;
	}
`;

const Left = styled.div`
	display: flex;
	align-items: center;
	font-size: 14px;
	letter-spacing: 1px;
	width: 100%;
	span {
		text-transform: uppercase;
	}

	@media (max-width: ${media.desktop}) {
		flex-wrap: wrap;
	}
`;

const LeftWithMobileBorder = styled(Left)`
	@media (max-width: ${media.desktop}) {
		padding: 0 0 20px;
		border-bottom: 1px solid #414141;
	}
`;

const Right = styled.div`
	align-items: center;
	color: #c6c6c6;
	font-size: 0.75em;
	img {
		height: 33px;
	}

	span {
		margin-right: 1em;
		white-space: nowrap;
	}

	&.hidden-mobile {
		display: flex;
	}

	&.hidden-desktop {
		display: none;
		flex-direction: column-reverse;
		img {
			margin-bottom: 1em;
		}
	}

	@media (max-width: ${media.desktop}) {
		display: flex;
		&.hidden-mobile {
			display: none;
		}

		&.hidden-desktop {
			display: flex;
		}
	}

	a {
		display: block;
	}
`;

const SocialIcons = styled.div`
	display: flex;
	margin-left: 75px;
	@media (max-width: ${media.desktop}) {
		margin-left: auto;
	}
`;

const Icon = styled.a`
	border: 1px solid #414141;
	width: 36px;
	height: 36px;
	border-radius: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 1em;

	&:last-child {
		margin-right: 0;
	}
`;

const NrlLink = styled.a`
	font-size: 12px;
	color: #c6c6c6;
	margin-right: 1em;
	text-decoration: none;
`;

const NRLCopyRight = ({ className }: any) => (
	<Right className={className}>
		<span>© {GAME_YEAR} National Rugby League</span>
		<img src={nrl_logo} alt="Game Logo NRL" />
	</Right>
);

export const Footer = () => (
	<FooterTagLine className={"footer"}>
		<FooterWrapper>
			<FooterContentWithBorder>
				<LeftWithMobileBorder>
					<span>Follow the NRL</span>
					<SocialIcons>
						<Icon
							href="https://www.facebook.com/nrl"
							target="_blank"
							rel="noreferrer noopener"
						>
							<img src={fb} alt="" />
						</Icon>
						<Icon
							href="https://twitter.com/nrl"
							target="_blank"
							rel="noreferrer noopener"
						>
							<img src={tw} alt="" />
						</Icon>
						<Icon
							href="https://www.instagram.com/nrl/"
							target="_blank"
							rel="noreferrer noopener"
						>
							<img src={inst} alt="" />
						</Icon>
						<Icon
							href="https://www.messenger.com/t/nrl"
							target="_blank"
							rel="noreferrer noopener"
						>
							<img src={wa} alt="" />
						</Icon>
					</SocialIcons>
				</LeftWithMobileBorder>

				<NRLCopyRight className="hidden-mobile" />
			</FooterContentWithBorder>

			<FooterContent>
				<Left>
					<NrlLink
						href="https://www.nrl.com/terms-of-use"
						target="_blank"
						rel="noreferrer noopener"
					>
						Terms of Use
					</NrlLink>
					<NrlLink
						href="https://www.nrl.com/privacy-policy"
						target="_blank"
						rel="noreferrer noopener"
					>
						Privacy Policy
					</NrlLink>
					<NrlLink
						href="https://www.nrl.com/careers"
						target="_blank"
						rel="noreferrer noopener"
					>
						Careers
					</NrlLink>
					<NrlLink
						href="https://help.nrl.com/"
						target="_blank"
						rel="noreferrer noopener"
					>
						Help
					</NrlLink>
					<NrlLink
						href="https://www.nrl.com/contact-us"
						target="_blank"
						rel="noreferrer noopener"
					>
						Contact Us
					</NrlLink>
					<NrlLink
						href="https://www.nrl.com/advertise-with-us"
						target="_blank"
						rel="noreferrer noopener"
					>
						Advertise With Us
					</NrlLink>
				</Left>
				<Right>
					<a
						href="https://geniussports.com/media-engagement/free-to-play-games/"
						target="_blank"
						rel="noreferrer noopener"
					>
						<img src={genius} alt="" />
					</a>
				</Right>
			</FooterContent>

			<NRLCopyRight className="hidden-desktop" />
		</FooterWrapper>
	</FooterTagLine>
);
export default Footer;
