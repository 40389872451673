import * as React from "react";
import { Component } from "react";

import styled from "styled-components";
import { Bling as Gpt } from "react-gpt";

Gpt.enableAsyncRendering();
Gpt.collapseEmptyDivs(true);
Gpt.setCentering(true);
Gpt.enableSingleRequest();
Gpt.syncCorrelator();

const AdServingWrapper = styled.div`
	display: flex;
    justify-content: center;
    margin: 2em auto;
    max-width: 970px;
		z-index: 5;

`;

const adNetwork = "78756360";
const adSite = "nrl.web";
const adSect = "ladder_predictor";
const kvCType = "index";

const pageURL = window.location.pathname; // extract article title from url
const pageURLArray = pageURL.split("/");
let articleURL = "";
while ( pageURLArray.length > 0 && articleURL === "" ) {
	articleURL = pageURLArray.pop() || "";
}

const kvURL = articleURL;
const adUnitPath = adNetwork + "/" + adSite + "/" + adSect;
const kconstray = adSect.split("/");
const kvSec1 = kconstray[ 0 ];
const kvSec2 = kconstray[ 1 ];
const kvSec3 = kconstray[ 2 ];

const mapping = {
	slot1: [
		{
			viewport: [ 0, 0 ],
			slot    : [ [ 1, 1 ], [ 320, 50 ] ],
		},
		{
			viewport: [ 600, 0 ],
			slot    : [ [ 1, 1 ], [ 320, 50 ] ],
		},
		{
			viewport: [ 728, 0 ],
			slot    : [ [ 1, 1 ], [ 728, 90 ] ],
		},
		{
			viewport: [ 1200, 0 ],
			slot    : [ [ 1, 1 ], [ 928, 250 ], [ 728, 50 ] ],
		},
		{
			viewport: [ 1400, 0 ],
			slot    : [ [ 1, 1 ], [ 970, 250 ] ],
		},
	],
	slot2: [
		{
			viewport: [ 0, 0 ],
			slot    : [ [ 1, 1 ], [ 320, 50 ] ],
		},
		{
			viewport: [ 768, 0 ],
			slot    : [ [ 1, 1 ], [ 300, 250 ] ],
		},
		{
			viewport: [ 1200, 0 ],
			slot    : [ [ 1, 1 ], [ 300, 250 ] ],
		},
		{
			viewport: [ 1400, 0 ],
			slot    : [ [ 1, 1 ], [ 300, 250 ] ],
		},
	],
};

const common_targeting = {
	sec1   : [ kvSec1 ],
	sec2   : [ kvSec2 ],
	sec3   : [ kvSec3 ],
	ctype  : [ kvCType ],
	pageURL: [ kvURL ],
};

const targetting = {
	slot1      : {
		pos: "1",
		...common_targeting,
	},
	slot2      : {
		pos: "2",
		...common_targeting,
	},
	out_of_page: {
		pos: "0",
		...common_targeting,
	},
};

const slotSize = {
	slot1: [
		[ 320, 50 ],
		[ 728, 90 ],
		[ 728, 50 ],
		[ 928, 250 ],
		[ 970, 250 ],
	],
	slot2: [
		[ 320, 50 ],
		// [ 728, 90 ],
		[ 300, 250 ],
		// [ 928, 250 ],
		// [ 600, 380 ]
	],
};

/**
 * @ignore
 */
interface IProps {
	slot: "slot1" | "slot2" | "out_of_page";
	round?: any;
}
interface IState {
	round_id: number | null;
}

export class AdServing extends Component<IProps, IState> {

	public state = {
		round_id: null,
	};

	public componentDidUpdate(): void {
		if (this.props.round) {
			const { round : {id : round_id} } = this.props;
			const old_round_id = this.state.round_id;
			if (!old_round_id) {
				this.setState(() => ( {
					round_id,
				} ));
				return;
			} else {
				if (old_round_id !== round_id) {
					this.setState(() => ( {
						round_id,
					} ));
					this.refreshAds();
				}
			}
		}

	}

	public refreshAds() {
		Gpt.refresh();
	}
	/**
	 * Get test ad param from url
	 */
	public getParameterByName(name: string) {
		const match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
		return match && decodeURIComponent(match[ 1 ].replace(/\+/g, " "));
	}

	/**
	 * @ignore
	 */
	public render() {
		const { slot } = this.props;
		const test = this.getParameterByName("adtest") || "";
		const target = test ? {
			...targetting[ slot ],
			adtest: [ test ],
		} : targetting[ slot ];

		const is_out_of_page = slot === "out_of_page";

		return is_out_of_page ? (
			<Gpt
				adUnitPath={adUnitPath}
				outOfPage={true}
				targeting={targetting.out_of_page}
			/>
		) : (
			<AdServingWrapper>
				<Gpt
					id={slot}
					adUnitPath={adUnitPath}
					targeting={target}
					slotSize={slotSize[ slot ]}
					sizeMapping={mapping[ slot ]}
				/>
			</AdServingWrapper>
		);

	}
}

export default AdServing;
