import { observable } from "mobx";
import CoreCollection from "../../core/collection";
import Api from "../../utils/Api";
import { Squad } from "../models/model.squad";


/**
 * Squads collection
 */
export class Squads extends CoreCollection<Squad> {

	@observable public executed: boolean = false;

	/**
	 * @ignore
	 */
	public get api() {
		return Api.JSON.squads;
	}

	/**
	 * @ignore
	 */
	public get model() {
		return Squad;
	}

	/**
	 * @ignore
	 */
	@observable
	public getSquadById(id: number) {
		const squads = this.getItems();
		return squads.find((squad: Squad) => squad.id === id);
	}

}
