import * as _ from 'lodash';
import { observer } from "mobx-react";
import * as React from 'react';
import { LoadingSpinner } from "../../components/AppLoading";
import {
	IRootStore,
	MobxContext
} from "../../modules/stores";
import { Match as TMatch } from '../../modules/stores/models/model.match';
import { Match } from './Fixture/Match';


@observer
class FixtureComponent extends React.Component<any> {

	static contextType = MobxContext;
	context!: React.ContextType<typeof MobxContext>;

	public get injected() {
		return this.context as IRootStore;
	}

	/**
	 * @ignore
	 **/
	public render() {
		const { squads, rounds } = this.injected;

		if (!squads.getItems().length || !rounds.getItems().length) {
			return (
				<LoadingSpinner with_cover_bg={true} position="center"/>
			);
		}
		return (
			<div>
				{_.map(this.matches, (match: TMatch, key: number) => (
					<Match key={key} match={match}/>
				))}
			</div>
		);
	}

	private get matches() {
		const { rounds: { getCurrentRound: round } } = this.injected;
		const final_matches = _.get(round, 'final_matches', []);
		if (!_.isEmpty(final_matches)) {
			return _.orderBy(final_matches, (match: TMatch) => {
				return new Date(match.date);
			})
		}
		return _.get(round, 'matches', []);
	}

}


export const Fixture = FixtureComponent;

export default Fixture;
