import { observer } from "mobx-react";
import * as React from "react";
import {
	Route,
	Redirect,
	RouteProps,
} from "react-router";
import {
	MobxContext,
	IRootStore,
} from "../../modules/stores";

type Props = RouteProps;

@observer
class NotAuthRouteComponent extends React.Component<Props> {
	public static contextType = MobxContext;
	public context!: React.ContextType<typeof MobxContext>;

	public get injected() {
		return this.context as IRootStore;
	}

	public render(): React.ReactNode {
		const { user } = this.injected;

		return !user.is_logged_in  ?  <Route {...this.props} /> : <Redirect to="/ladder"/>;
	}

}

export const NotAuthRoute = NotAuthRouteComponent;
