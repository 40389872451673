import * as _ from 'lodash';
import { observer } from "mobx-react";
import * as React from 'react';
import styled from "styled-components";
import media from "../../assets/css/media";
import { slideDown } from "../../components";
import {
	IRootStore,
	MobxContext
} from "../../modules/stores";
import { Rounds } from "../../modules/stores/collections";


const Wrapper = styled.div`
	display: flex;
	padding: 1.5em;
	background: #D0021B;
	max-width: 100%;
	justify-content: center;
	animation: ${slideDown} 0.3s ease-in;
	overflow: hidden;
	border-radius: 5px;
	color: #fff;
	font-size: 13px;
	letter-spacing: 2px;
	
	@media(max-width: ${media.desktop}) {
		margin: 0 10px;
	}
`;

@observer
class MissedRoundsComponent extends React.Component {

	static contextType = MobxContext;
	context!: React.ContextType<typeof MobxContext>;

	public get injected() {
		return this.context as IRootStore;
	}

	public get roundNames() {
		const { rounds: { getUnPickedRounds: rounds } } = this.injected;

		const last = rounds.length > 1 && _.last(rounds);
		let result = '';
		_.each(rounds, (round: number) => {
			let name = round + '';
			if (Rounds.final_names[round]) {
				name = `${Rounds.final_names[round]}`;
			}

			if (last && last === round) {
				result += ` & ${name}`;
				return;
			}

			if (result) {
				result += `, ${name}`;
			} else {
				result += `${name}`;
			}

		});


		return result;
	}

	/**
	 * @ignore
	 **/
	public render() {
		const { rounds: { getUnPickedRounds: rounds } } = this.injected;
		const remove_round = this.roundNames === 'Finals Week 1' ||
			this.roundNames === 'Finals Week 2' || this.roundNames === 'Finals Week 3' || this.roundNames === 'Grand final';
		return rounds.length ? (
			<Wrapper>
				You have missing selections in {!remove_round && 'Round'} {this.roundNames}
			</Wrapper>
		) : null;
	}
}

export const MissedRounds = MissedRoundsComponent;

export default MissedRounds;