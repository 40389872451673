import * as _ from "lodash";
import { observer } from "mobx-react";
import React, { Component } from "react";
import styled, { css } from "styled-components";
import media from "../../../../assets/css/media";
import theme from "../../../../assets/css/theme";
import { Accordion } from "../../../../components/Accordion";
import {
	MobxContext,
	IRootStore,
} from "../../../../modules/stores";
import { Match } from "../../../../modules/stores/models/model.match";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1em;

	@media( max-width: ${media.desktop} ) {
		justify-content: center;
		flex-wrap: wrap;
	}
`;

interface IItem {
	is_draw: boolean;
	is_picked: boolean;
	is_can_be_selected: boolean;

}

const Item = styled.div<IItem>`
	border: 1px solid #E8E8E8;
	width: ${({ is_draw }) => is_draw ? "81px" : "41px"};
	min-width: ${({ is_draw }) => is_draw ? "81px" : "41px"};
	height: 41px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	color: #767676;
	font-family: ${theme.base_font};
	letter-spacing: 2.5px;
	font-weight: bold;
	text-transform: uppercase;
	transition: all 0.3s ease-in;
	opacity: 0.75;
	cursor: not-allowed;
	margin-right: 0.25em;
	&:hover {
		//box-shadow: 0 0 4px 0 rgba(0,207,93,1);
		border: 1px solid #00cf5d;
		color: #00CF5D
	}

	${({ is_picked }) => is_picked && css`
		background: #00CF5D;
		color: #fff;

		&:hover {
			box-shadow: 0 0 4px 0 #006833;
			border: 1px solid #006833;
			color: #fff
		}
	`}

	${({ is_can_be_selected }) => is_can_be_selected && css`
		pointer-events: all;
		cursor: pointer;
		opacity: 1;
	`}

	@media( max-width: ${media.desktop} ) {
		margin-bottom: 0.5em;
	}
`;

interface IProps {
	match: Match;
}

@observer
class DrawContent extends Component<IProps> {

	public get injected() {
		return this.context as IRootStore;
	}

	public get currentPick() {
		const { picks } = this.injected;
		const { match } = this.props;

		return picks.getByID(match.id) || {};
	}

	private get difference_points() {
		const { match } = this.props;
		return Math.abs(match.away_score - match.home_score);
	}

	public static contextType = MobxContext;
	public context!: React.ContextType<typeof MobxContext>;

	constructor(props: IProps) {
		super(props);
		_.bindAll(this, [
			"makeDraw",
		]);
	}

	public render() {
		const features = Match.draw_items;
		const { match } = this.props;
		const pick = this.currentPick;
		const is_marked = !_.isUndefined(pick.draw_index) ? ~pick.draw_index : pick.is_draw;
		const points_label_complete = this.difference_points ? `By ${this.difference_points} points` : "Drawn Match";
		const points_label = is_marked ?
			pick.is_draw
				? "Drawn Match"
				: `By ${Match.draw_items[ pick.draw_index ]} points`
			: "By 12 points";

		const can_be_selected = !!pick.squad_id;
		return (
			<Accordion
				title={match.isComplete ? points_label_complete : points_label}
				is_locked={match.isComplete}
				is_final={match.is_final}
			>
				<Wrapper>
					<Item
						is_draw={true}
						is_can_be_selected={true}
						is_picked={!!is_marked && pick.is_draw}
						onClick={_.partial(this.makeDraw, Boolean(!pick.is_draw), pick.draw_index)}
					>
						{"draw"}
					</Item>
					{features.map((item: string, index: number) => (
							<Item
								key={index}
								is_draw={item === "draw"}
								is_can_be_selected={can_be_selected}
								is_picked={!!is_marked && pick.draw_index === index && !pick.is_draw}
								onClick={can_be_selected ? _.partial(this.makeDraw, false, index) : undefined}
							>
								{item}
							</Item>
						),
					)}
				</Wrapper>
			</Accordion>
		);
	}

	private makeDraw(is_draw: boolean, draw_index: number) {
		const { picks, rounds: { getCurrentRound: round } } = this.injected;
		const { match } = this.props;

		if ( picks && round ) {
			picks.makeDraw({
				id        : match.id,
				round_id  : round.id,
				draw_index,
				is_draw,
				is_final  : round.is_final,
				is_grand_final  : round.is_grand_final,
				form_data:null
			});

			round.setPickMatchIDs(match.id);
		}
	}

}

export default DrawContent;
