import { get } from "lodash";
import {
	observable,
	runInAction,
	action
} from "mobx";

import Api from "../../utils/Api";
import { isOFFSeason } from "../../utils/dom_helpers";
import Storage from "../../utils/Storage";


/**
 * User model
 */
export class UserModel {
	// 	@observable is_logged_in: boolean = trueyarn;
	@observable public is_logged_in: boolean = !!Storage.get("is_authorized") && !isOFFSeason();
	@observable public is_test_in: boolean = false;
	@observable public id: number = 0;
	@observable public tokenData: string = "";
	@observable public errors: object = [];
	@observable public given_name: string = "";
	@observable public family_name: string = "";
	public status = "initial";

	/**
	 * @ignore
	 */
	@action
	public logout = async () => {
		this.is_logged_in = false;
		Storage.remove("is_authorized");
		Storage.remove("favorite_club");
		Storage.remove("user");
		Storage.remove("picks");
		this.redirectToNRLAuthLogout();
	};

	/**
	 * @ignore
	 */
	public redirectToNRLAuthLogout() {
		const nrl_auth_logout_url =
			process.env.REACT_APP_NRL_URL +
			"/account/logout?post_logout_redirect_uri=" +
			process.env.REACT_APP_ABSPATH
			;

		window.location.assign(nrl_auth_logout_url);
	}

	/**
	 * @ignore
	 */
	 public get age(): any {
		const birthday = get(JSON.parse(Storage.get('user') || '{}'), 'birthday');

		return birthday ? new Date().getFullYear() - new Date(birthday).getFullYear() : 0;
	}

	/**
	 * @ignore
	 */
	public get nrl_account_id(): any {
		const nrlID = get(JSON.parse(Storage.get('user') || '{}'), 'nrl_account_id');

		return nrlID ? Number(nrlID) : 0;
	}

	/**
	 * @ignore
	 */
	@action
	public getJwtAsync = async (code: string) => {
		try {
			const result = await Api.Auth.login({
				code: code.replace(/\s/g, "+"),
				redirect_url: process.env.REACT_APP_SSO_REDIRECT_URL,
			});
			runInAction(() => {
				try {
					if (!result.errors.length) {
						const nrl_team = get(result, 'result.nrl_club', false);
						const nrl_account_id = get(result, 'result.nrl_account_id', 0);
						this.given_name = get(result, 'result.given_name_initial', false);

						this.family_name = nrl_team;


						if (nrl_team) {
							const favorite = parseInt(Storage.get("favorite_club") || "", 10);
							if (!favorite) {
								Storage.set("favorite_club", nrl_team);
							}

							this.sendAnalitics(nrl_account_id);


						}
						this.is_logged_in = true;
						this.saveUserToToStorage(result.result);

					} else {

						this.errors = result.errors;
						window.location.assign("/");
						console.log(this.errors);

					}
				} catch (e) {
					console.log(e);
					window.location.assign("/");
				}

			});
		} catch (error) {
			runInAction(() => {
				this.status = "error";
			});
		}
	};

	private saveUserToToStorage(user: object) {
		Storage.set("user", user);
		Storage.set("is_authorized", true);

	}

	private sendAnalitics(nrl_id: number) {
		window.dataLayer.push({
			'event': 'logged_in',
			'nrl_id': nrl_id,
		});
	}
}
