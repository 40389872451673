import logo_500011 from "../../assets/img/squads/500011.svg";
import logo_500013 from "../../assets/img/squads/500013.svg";
import logo_500010 from "../../assets/img/squads/500010.svg";
import logo_500028 from "../../assets/img/squads/500028.svg";
import logo_500004 from "../../assets/img/squads/500004.svg";
import logo_500002 from "../../assets/img/squads/500002.svg";
import logo_500021 from "../../assets/img/squads/500021.svg";
import logo_500003 from "../../assets/img/squads/500003.svg";
import logo_500012 from "../../assets/img/squads/500012.svg";
import logo_500031 from "../../assets/img/squads/500031.svg";
import logo_500014 from "../../assets/img/squads/500014.svg";
import logo_500005 from "../../assets/img/squads/500005.svg";
import logo_500022 from "../../assets/img/squads/500022.svg";
import logo_500001 from "../../assets/img/squads/500001.svg";
import logo_500032 from "../../assets/img/squads/500032.svg";
import logo_500023 from "../../assets/img/squads/500023.svg";
import logo_500723 from "../../assets/img/squads/500723.svg";
import default_theme from "../../assets/img/squads/default_theme.png";

export const SQUADS_THEME = {
	500011: {
		color: "#6C1D45",
		image: logo_500011,
	},
	500013: {
		color: "#009A4E",
		image: logo_500013,
	},
	500010: {
		color: "#00468B",
		image: logo_500010,
	},
	500028: {
		color: "#1F7EB2",
		image: logo_500028,
	},
	500004: {
		color: "#015E9B",
		image: logo_500004,
	},
	500002: {
		color: "#012C72",
		image: logo_500002,
	},
	500021: {
		color: "#592B8C",
		image: logo_500021,
	},
	500003: {
		color: "#013B73",
		image: logo_500003,
	},
	500012: {
		color: "#0C2341",
		image: logo_500012,
	},
	500031: {
		color: "#012C72",
		image: logo_500031,
	},
	500014: {
		color: "#2C2F30",
		image: logo_500014,
	},
	500005: {
		color: "#013B19",
		image: logo_500005,
	},
	500022: {
		color: "#CD232C",
		image: logo_500022,
	},
	500001: {
		color: "#CD232C",
		image: logo_500001,
	},
	500032: {
		color: "#2A2F34",
		image: logo_500032,
	},
	500023: {
		color: "#272727",
		image: logo_500023,
	},
	500723: {
		color: "#fc141d",
		image: logo_500723,
	},
	default_theme: {
		color: "#202020",
		image: default_theme,
	},
};

export const GAME_YEAR = process.env.REACT_APP_GAME_YEAR || "2024";
